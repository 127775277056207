import React, {useCallback, useMemo, useState} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Container from "react-bootstrap/Container"

import '../style/Satatistics.css'

import {ResponsivePie} from '@nivo/pie'
import {ResponsiveLine} from '@nivo/line'
import { ResponsiveCalendar } from '@nivo/calendar'
import { ResponsiveBar } from '@nivo/bar'




export default function Statistics(props) {

    const data = [
        {
            "id": "CO2",
            "label": "CO2",
            "value": 595,
            "color": "hsl(247, 70%, 50%)"
        },
        {
            "id": "IPL",
            "label": "IPL",
            "value": 59,
            "color": "hsl(51, 70%, 50%)"
        },
        {
            "id": "Pico",
            "label": "Pico",
            "value": 199,
            "color": "hsl(85, 70%, 50%)"
        },
        {
            "id": "Q-Switch",
            "label": "Q-Switch",
            "value": 297,
            "color": "hsl(68, 70%, 50%)"
        },
        {
            "id": "Venus Freezeplus",
            "label": "Venus Freezeplus",
            "value": 338,
            "color": "hsl(147, 70%, 50%)"
        }
    ]

    const data1= [
        {
            "id": "CO2",
            "color": "hsl(5, 70%, 50%)",
            "data": [
                {
                    "x": "January",
                    "y": 44
                },
                {
                    "x": "February",
                    "y": 127
                },
                {
                    "x": "March",
                    "y": 35
                },
                {
                    "x": "April",
                    "y": 45
                },
                {
                    "x": "May",
                    "y": 170
                },
                {
                    "x": "June",
                    "y": 277
                },
                {
                    "x": "July",
                    "y": 119
                },
                {
                    "x": "August",
                    "y": 103
                },
                {
                    "x": "September",
                    "y": 294
                },
                {
                    "x": "October",
                    "y": 292
                },
                {
                    "x": "November",
                    "y": 288
                },
                {
                    "x": "December",
                    "y": 166
                }
            ]
        },
        {
            "id": "IPL",
            "color": "hsl(177, 70%, 50%)",
            "data": [
                {
                    "x": "January",
                    "y": 35
                },
                {
                    "x": "February",
                    "y": 33
                },
                {
                    "x": "March",
                    "y": 228
                },
                {
                    "x": "April",
                    "y": 105
                },
                {
                    "x": "May",
                    "y": 207
                },
                {
                    "x": "June",
                    "y": 284
                },
                {
                    "x": "July",
                    "y": 246
                },
                {
                    "x": "August",
                    "y": 17
                },
                {
                    "x": "September",
                    "y": 127
                },
                {
                    "x": "October",
                    "y": 291
                },
                {
                    "x": "November",
                    "y": 248
                },
                {
                    "x": "December",
                    "y": 100
                }
            ]
        },
        {
            "id": "Pico",
            "color": "hsl(111, 70%, 50%)",
            "data": [
                {
                    "x": "January",
                    "y": 217
                },
                {
                    "x": "February",
                    "y": 182
                },
                {
                    "x": "March",
                    "y": 159
                },
                {
                    "x": "April",
                    "y": 271
                },
                {
                    "x": "May",
                    "y": 95
                },
                {
                    "x": "June",
                    "y": 174
                },
                {
                    "x": "July",
                    "y": 211
                },
                {
                    "x": "August",
                    "y": 40
                },
                {
                    "x": "September",
                    "y": 287
                },
                {
                    "x": "October",
                    "y": 64
                },
                {
                    "x": "November",
                    "y": 290
                },
                {
                    "x": "December",
                    "y": 23
                }
            ]
        },
        {
            "id": "Q-Switch",
            "color": "hsl(264, 70%, 50%)",
            "data": [
                {
                    "x": "January",
                    "y": 210
                },
                {
                    "x": "February",
                    "y": 2
                },
                {
                    "x": "March",
                    "y": 200
                },
                {
                    "x": "April",
                    "y": 286
                },
                {
                    "x": "May",
                    "y": 97
                },
                {
                    "x": "June",
                    "y": 72
                },
                {
                    "x": "July",
                    "y": 24
                },
                {
                    "x": "August",
                    "y": 203
                },
                {
                    "x": "September",
                    "y": 27
                },
                {
                    "x": "October",
                    "y": 264
                },
                {
                    "x": "November",
                    "y": 232
                },
                {
                    "x": "December",
                    "y": 288
                }
            ]
        },
        {
            "id": "Venus Freezplus",
            "color": "hsl(108, 70%, 50%)",
            "data": [
                {
                    "x": "January",
                    "y": 89
                },
                {
                    "x": "February",
                    "y": 224
                },
                {
                    "x": "March",
                    "y": 273
                },
                {
                    "x": "April",
                    "y": 34
                },
                {
                    "x": "May",
                    "y": 23
                },
                {
                    "x": "June",
                    "y": 203
                },
                {
                    "x": "July",
                    "y": 246
                },
                {
                    "x": "August",
                    "y": 66
                },
                {
                    "x": "September",
                    "y": 55
                },
                {
                    "x": "October",
                    "y": 48
                },
                {
                    "x": "November",
                    "y": 126
                },
                {
                    "x": "December",
                    "y": 184
                }
            ]
        }
    ]

    const data2= [
        {
            "clinic": "Panama City",
            "CO2": 191,
            "CO2Color": "hsl(224, 70%, 50%)",
            "IPL": 139,
            "IPLColor": "hsl(30, 70%, 50%)",
            "Pico": 92,
            "PicoColor": "hsl(302, 70%, 50%)",
            "Q-Switch": 16,
            "SwitchColor": "hsl(253, 70%, 50%)",
            "Venus Freezeplus": 184,
            "Venus FreezeplusColor": "hsl(309, 70%, 50%)",
        },
        {
            "clinic": "Boca Raton",
            "CO2": 165,
            "CO2Color": "hsl(247, 70%, 50%)",
            "IPL": 81,
            "IPLColor": "hsl(258, 70%, 50%)",
            "Pico": 134,
            "PicoColor": "hsl(95, 70%, 50%)",
            "Switch": 189,
            "SwitchColor": "hsl(38, 70%, 50%)",
            "Venus Freezeplus": 192,
            "Venus FreezeplusColor": "hsl(213, 70%, 50%)",

        },
        {
            "clinic": "Orlando",
            "CO2": 105,
            "CO2Color": "hsl(321, 70%, 50%)",
            "IPL": 53,
            "IPLColor": "hsl(118, 70%, 50%)",
            "Pico": 36,
            "PicoColor": "hsl(347, 70%, 50%)",
            "Switch": 16,
            "SwitchColor": "hsl(296, 70%, 50%)",
            "Venus Freezeplus": 75,
            "Venus FreezeplusColor": "hsl(113, 70%, 50%)",
        },
        {
            "clinic": "Fort Lauderdale",
            "CO2": 93,
            "CO2Color": "hsl(289, 70%, 50%)",
            "IPL": 181,
            "IPLColor": "hsl(175, 70%, 50%)",
            "Pico": 124,
            "PicoColor": "hsl(15, 70%, 50%)",
            "Switch": 14,
            "SwitchColor": "hsl(340, 70%, 50%)",
            "Venus Freezeplus": 169,
            "Venus FreezeplusColor": "hsl(298, 70%, 50%)",
        },
        {
            "clinic": "Hollywood",
            "CO2": 178,
            "CO2Color": "hsl(92, 70%, 50%)",
            "IPL": 39,
            "IPLColor": "hsl(261, 70%, 50%)",
            "Pico": 55,
            "PicoColor": "hsl(269, 70%, 50%)",
            "Switch": 150,
            "SwitchColor": "hsl(127, 70%, 50%)",
            "Venus Freezeplus": 28,
            "Venus FreezeplusColor": "hsl(56, 70%, 50%)",
        },
        {
            "clinic": "West Palm Beach",
            "CO2": 184,
            "CO2Color": "hsl(122, 70%, 50%)",
            "IPL": 93,
            "IPLColor": "hsl(209, 70%, 50%)",
            "Pico": 173,
            "PicoColor": "hsl(83, 70%, 50%)",
            "Switch": 175,
            "SwitchColor": "hsl(270, 70%, 50%)",
            "Venus Freezeplus": 164,
            "Venus FreezeplusColor": "hsl(257, 70%, 50%)",
        },

    ]


    return (
        <div className={'statisticsContainer'}>
            <Row>
                <h3 className={'statisticsTitle'}> Dashboard Statistics</h3>
            </Row>
                <Row>
                <Col md={5}>
                    <h6 className={'statisticsSubTitle'}>Bookings by Laser Type </h6>
                    <div className={'statisticsChart'}>
                        <ResponsivePie
                            data={data}
                            margin={{top: 40, right: 80, bottom: 80, left: 80}}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{from: 'color'}}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{from: 'color', modifiers: [['darker', 2]]}}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    size: 4,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            fill={[
                                {
                                    match: {
                                        id: 'Pico'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'Venus Freezeplus'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'Q-Switch'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'IPL'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'CO2'
                                    },
                                    id: 'lines'
                                },
                                {
                                    match: {
                                        id: 'lisp'
                                    },
                                    id: 'lines'
                                },
                                {
                                    match: {
                                        id: 'elixir'
                                    },
                                    id: 'lines'
                                },
                                {
                                    match: {
                                        id: 'javascript'
                                    },
                                    id: 'lines'
                                }
                            ]}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 56,
                                    itemsSpacing: 0,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: '#999',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>

                </Col>
                <Col md={7}>
                    <h6 className={'statisticsSubTitle'}>Lasers by Clinics </h6>
                    <div className={'statisticsChart'}>
                        <ResponsiveBar
                            data={data2}
                            keys={[ 'CO2', 'IPL', 'Pico', 'Switch', 'Venus Freezeplus'
                            ]}
                            indexBy="clinic"
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={{ scheme: 'nivo' }}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: '#38bcb2',
                                    size: 4,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: '#eed312',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            fill={[
                                {
                                    match: {
                                        id: 'Venus Freezeplus'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'Pico'
                                    },
                                    id: 'lines'
                                }
                            ]}
                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'clinic',
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Lasers',
                                legendPosition: 'middle',
                                legendOffset: -40
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemsSpacing: 2,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 20,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                        />
                    </div>


                </Col>

            </Row>
        <Row>
            <Col md={12}>
                <h6 className={'statisticsSubTitle'}>Bookings by Month </h6>
                <div className={'statisticsChart'}>

                    <ResponsiveLine
                        data={data1}
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        xScale={{ type: 'point' }}
                        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                        yFormat=" >-.2f"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'month',
                            legendOffset: 36,
                            legendPosition: 'middle'
                        }}
                        axisLeft={{
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'count',
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                            {
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 100,
                                translateY: 0,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 80,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                    />

                </div>

            </Col>

        </Row>


        </div>

    )
}