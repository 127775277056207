import React from "react";
import Map from "../../widgets/Map";

import * as Icon from "react-bootstrap-icons";


//TODO: use context to route and lat, lng and handleInputChange

export default function ClinicInput(props) {

    return (
        <div id="medicalClinic" className="content">
            <div className="form-group">
                <div className={'stageInput'}>
                    <label htmlFor="medicalClinic">Select Clinic</label>
                    <select className={'form-control'} id="medicalClinic"
                            onChange={props.handleInputChange}
                            value={props.selectedMedicalClinic.clinicName}>
                        {
                            props.clinics.map((item) =>
                                <option key={item.toString()}>{item.clinicName}</option>)
                        }
                    </select>
                </div>
                {props.route?
                    <Map lat={props.selectedMedicalClinic.lat}
                         lng={props.selectedMedicalClinic.lng}
                         route={props.route}
                         toLat={props.toMedicalClinic.lat}
                         toLng={props.toMedicalClinic.lng}
                         updateDrivingTime={props.updateDrivingTime}
                         styleMap={'map'}

                    />
                    :
                    <Map clinics={[props.selectedMedicalClinic]}
                         route={props.route}
                         styleMap={'map'}

                    />
                }

                <div className={'stepperBtnClinic'}>
                    <button className="btn btn-outline-secondary"
                            onClick={() => props.stepper.previous()}>
                        <Icon.CaretLeft size={17}/> Previous
                    </button>
                    <button className="btn btn-outline-primary"
                            onClick={() => props.stepper.next()}>
                        Next <Icon.CaretRight size={15}/>
                    </button>
                </div>
            </div>
        </div>
    )
}