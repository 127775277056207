/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const getDeliveries = /* GraphQL */ `
    query getDeliveries {
        listDeliverys {
            items {
                drivingTime
                fromClinic {
                    clinicName
                }
                booking {
                    laser {
                        typeID
                        serialNumber
                    }
                    startDate
                    medicalClinic {
                        clinicName
                    }
                    createdAt
                }
                bookingID
                id
        }
    }
}
`;

export const deliveryByBookingID= /* GraphQL */ `
	query deliveryByBookingID($bookingID: ID) {
  		listDeliverys(filter: {bookingID: {contains: $bookingID}}) {
    			items {
      				bookingID
     	 			createdAt
      				drivingTime
      				fromClinicID
      				operationID
      				id
    			}
  		}
	}
`;
export const posts = /* GraphQL */ `
  query Posts {
    posts {
      id
      title
      comments {
        postId
        content
      }
    }
  }
`;
export const getTodos = /* GraphQL */ `
  query GetTodos {
    getTodos {
      id
      name
      description
      priority
      a
      createdAt
      updatedAt
    }
  }
`;
export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      priority
      a
      createdAt
      updatedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        priority
        a
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLaser = /* GraphQL */ `
  query GetLaser($id: ID!) {
    getLaser(id: $id) {
      id
      companyID
      serialNumber
      typeID
      locationID
      activeStatus
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      type {
        id
        companyID
        laserName
        description
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      currentClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLasers = /* GraphQL */ `
  query ListLasers(
    $filter: ModelLaserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLasers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMalfunction = /* GraphQL */ `
  query GetMalfunction($id: ID!) {
    getMalfunction(id: $id) {
      id
      laserID
      serviceID
      description
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMalfunctions = /* GraphQL */ `
  query ListMalfunctions(
    $filter: ModelMalfunctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMalfunctions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        laserID
        serviceID
        description
        laser {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLaserType = /* GraphQL */ `
  query GetLaserType($id: ID!) {
    getLaserType(id: $id) {
      id
      companyID
      laserName
      description
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLaserTypes = /* GraphQL */ `
  query ListLaserTypes(
    $filter: ModelLaserTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLaserTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        laserName
        description
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      companyID
      financeID
      dueDate
      amount
      item
      unitPrice
      quantity
      description
      status
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      finance {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        financeID
        dueDate
        amount
        item
        unitPrice
        quantity
        description
        status
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        finance {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      companyName
      medicalClinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        nextToken
      }
      types {
        items {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMedicalClinic = /* GraphQL */ `
  query GetMedicalClinic($id: ID!) {
    getMedicalClinic(id: $id) {
      id
      companyID
      clinicName
      address
      country
      state
      lat
      lng
      operationID
      serviceID
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMedicalClinics = /* GraphQL */ `
  query ListMedicalClinics(
    $filter: ModelMedicalClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicalClinics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      companyID
      firstName
      lastName
      email
      phone
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOperation = /* GraphQL */ `
  query GetOperation($id: ID!) {
    getOperation(id: $id) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      deliveries {
        items {
          id
          fromClinicID
          bookingID
          operationID
          drivingTime
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOperations = /* GraphQL */ `
  query ListOperations(
    $filter: ModelOperationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOperations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getManager = /* GraphQL */ `
  query GetManager($id: ID!) {
    getManager(id: $id) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listManagers = /* GraphQL */ `
  query ListManagers(
    $filter: ModelManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManagers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFinance = /* GraphQL */ `
  query GetFinance($id: ID!) {
    getFinance(id: $id) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFinances = /* GraphQL */ `
  query ListFinances(
    $filter: ModelFinanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      laserID
      medicalClinicID
      customerID
      startDate
      endDate
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      medicalClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        laserID
        medicalClinicID
        customerID
        startDate
        endDate
        laser {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        medicalClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        customer {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDelivery = /* GraphQL */ `
  query GetDelivery($id: ID!) {
    getDelivery(id: $id) {
      id
      fromClinicID
      bookingID
      operationID
      drivingTime
      booking {
        id
        laserID
        medicalClinicID
        customerID
        startDate
        endDate
        laser {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        medicalClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        customer {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fromClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDeliverys = /* GraphQL */ `
  query ListDeliverys(
    $filter: ModelDeliveryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliverys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fromClinicID
        bookingID
        operationID
        drivingTime
        booking {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        fromClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const todosByStatus = /* GraphQL */ `
  query TodosByStatus(
    $description: String
    $sortDirection: ModelSortDirection
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    todosByStatus(
      description: $description
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        priority
        a
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
