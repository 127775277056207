import React, {useState, useEffect, useReducer} from 'react'

import Container from "react-bootstrap/Container";

import '../style/Card.css'
import Card from "react-bootstrap/Card";
import * as Icon from "react-bootstrap-icons";
import {API, graphqlOperation, Storage} from "aws-amplify";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import momentParser from 'moment';

import {getDeliveries, listLaserTypes, listMalfunctions, listMedicalClinics} from '../graphql/queries';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import '../style/Operations.css'
import {Roller} from "react-spinners-css";
import {LinkContainer} from "react-router-bootstrap";


const initialStateFilters = {deliveryDate: '', toMedicalClinic: ''}

export default function Service() {

    const [myMalfunction, setMalfunctions] = useState([])
    const [clinics, setClinics] = useState([])

    useEffect(() => {

        async function fetchData() {

            try {


                //custom resolver
                let malfunctionsData = await API.graphql(graphqlOperation(listMalfunctions))

                let arrMalfunctions = malfunctionsData.data.listMalfunctions.items

                let data = await API.graphql({query: listLaserTypes})

                let types = data.data.listLaserTypes.items;

                data = await API.graphql({query: listMedicalClinics})

                let clinics = data.data.listMedicalClinics.items;

                let malfunctions = arrMalfunctions.map((malfunction) => {

                    let laserName = types.find((type)=> type.id === malfunction.laser.typeID).laserName

                    let clinicName = clinics.find((clinic)=> clinic.id === malfunction.laser.locationID).clinicName

                    return {
                        id: malfunction.id,
                        laserType: laserName,
                        malfunctionDate: malfunction.createdAt.substring(0, 10),
                        clinic: clinicName,
                        serialNumber: malfunction.laser.serialNumber,
                        description: malfunction.description
                    }
                })



                //refactor TODO custom hook
                let images = await Storage.list('f4f21382-9179-4da0-9630-8919a203950d/')
                let arrImg = [];

                for (const img of images) {
                    let imgUrl = await Storage.get(img.key);
                    //let imgUrl = await Storage.get(img.key, {expires: 60});
                    const typeLaser = types.find(type => 'f4f21382-9179-4da0-9630-8919a203950d/'+type.id === img.key);


                    arrImg.push({key: img.key, laserName:typeLaser.laserName,  imgUrl: imgUrl})
                }


                malfunctions.map((malfunction) => {


                    let img = arrImg.find(img => img.laserName === malfunction.laserType)
                    malfunction.imgUrl = img.imgUrl;

                })
                setMalfunctions(malfunctions)

            } catch (err) {

                console.log(err)
            }
        }

        fetchData();
    }, [])






    return (

        <Container>
            <Row>
                <div className={'operationsTop'}>
                    <div className={'operationTitle'}>
                        <h3>Lasers Malfunctions</h3>
                    </div>

                </div>
            </Row>
            <Container className={'bookingList'}>
                {myMalfunction.length === 0 ?
                    <Roller className={'spinner'} color="#4d94ff"/> :
                    <Container className={'bookingList'}>
                        <ListMalfunctions
                            malfunctions={myMalfunction}/>
                    </Container>
                }
            </Container>
        </Container>

    )

}





function ListMalfunctions(props) {

    return (
        props.malfunctions.map((malfunction) =>
            <Card className={'item'} border='light'>
                <Row className={'cardWrapper'}>
                                <span className={'laserImg'}>
                                    <Image src={malfunction.imgUrl}/>
                                </span>
                    <span key={malfunction.toString()} className={'cardInfo'} border="light">
                                    <Row>
                                        <Col md={5}>
                                        <h5>{malfunction.laserType}</h5>
                                        <div>Malfunction Date:<span
                                            className={'itemData'}>{malfunction.malfunctionDate}</span>
                                    </div>
                                     <div>Serial Number:<span
                                         className={'itemData'}>{malfunction.serialNumber}</span>
                                    </div>
                                    <div>Clinic:<span
                                        className={'itemData'}>{malfunction.clinic}</span>
                                    </div>

                                    <div>Description:<span
                                        className={'itemData'}>{malfunction.description}</span>
                                    </div>

                                        </Col>
                                        <Col md={{span: 7}}>
                                            <Row>
                                            </Row>
                                        <Col>
                                                <Row className={'bookingBtns'}>
                                                        <Button variant={"outline-success"}
                                                                size="sm">Malfunction Fixed
                                                            <Icon.BagCheck size={15}/>
                                                        </Button>
                                                            <Button variant={"outline-warning"}
                                                                    size="sm">Update Details
                                                                <Icon.ArrowRepeat size={15}/>
                                                            </Button>
                                                </Row>
                                        </Col>
                                        </Col>
                                    </Row>
                                </span>
                </Row>
            </Card>
        ))
}

