import {createContext, useEffect, useReducer} from "react";
import {authReducer} from "../state_management/reducers/authReducer";
import {Auth} from "aws-amplify";
import {actionCreators} from "../state_management/actions/authActions";

export const AuthContext = createContext();

let initialAuthState = {status: 'signIn', loading: false, error: ''};

export function AuthProvider(props){

    if (localStorage['authentication']) {
        let userName = localStorage['userName']
        initialAuthState = {
            status: 'signedIn',
            loading: false,
            error: '',
            user : { userName: userName}
        }
    }

    const [authState, dispatch] = useReducer(authReducer, initialAuthState)

    useEffect(() => {

        loggedInUser()
    }, []);

    async function loggedInUser() {

        try {
            let user = await Auth.currentAuthenticatedUser()
            localStorage['authentication'] = true
            localStorage['userName'] = user.username
            dispatch(actionCreators.signedIn(false, '', {
                userName: user.username,
                email: user.attributes.email,
                company: user.attributes['custom:company']
            }))

        } catch (err) {
            dispatch(actionCreators.signIn(false, ''))
        }
    }

    async function signOut(){

        try {
            dispatch(actionCreators.signOut(false, ''))
            localStorage.removeItem('authentication');
            localStorage.removeItem('userName');
            await Auth.signOut();
            dispatch(actionCreators.signIn(false, ''))
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <AuthContext.Provider value={{
            authState: authState,
            dispatch: dispatch,
            signOut: signOut
        }}>
            {props.children}
        </AuthContext.Provider>

        )
}

