import React from "react";
import * as Icon from "react-bootstrap-icons";
import Card from "react-bootstrap/Card";
import {LinkContainer} from "react-router-bootstrap";
import {Roller} from "react-spinners-css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default function Submit(props) {

    return (
        <div id="submit" className="content">
            <div className="form-group">
                <div className={'stepperBtnSubmit'}>
                    {props.form.formStatus === 'createMode' || props.form.formStatus === 'updateMode' ?
                        <Col md={4}>
                        <button className="btn btn-outline-secondary"
                                onClick={() => props.stepper.previous()}>
                            <Icon.CaretLeft size={17}/> Previous
                        </button>
                        </Col>
                        :

                        <Row className={props.createMode ? "bookingCreatedBtn" : "updateSubmitBtn"}>

                                {
                                props.createMode ?
                                    <Col md={{span:3, offset:1}}>
                                    <button className="btn btn-outline-primary"
                                            onClick={props.initForm}>
                                        Create Another {props.resource.one} <Icon.BagPlus size={17}/>
                                    </button>
                                    </Col>
                                    :
                                    <Col md={3}>
                                    </Col>
                            }
                            <Col md={3}>
                                <LinkContainer to={"/Home"}>
                                <button className="btn btn-outline-secondary"
                                        onClick={''}>
                                    Menu <Icon.HouseDoor size={17}/>
                                </button>
                            </LinkContainer>
                            </Col>
                            <Col md={3}>
                                <LinkContainer to={props.navOption}>
                                <button className="btn btn-outline-info"
                                        onClick={''}>
                                    {props.resource.plural} <Icon.CartCheck size={17}/>
                                </button>
                            </LinkContainer>
                            </Col>




                        </Row>
                    }
                </div>
                <div>
                    <Card className={'bookingDetails'}>
                        <Card.Body>
                            <Card.Title
                                className={'bookingDetailsTitle'}><span>{props.resource.one} Details <Icon.BagCheck
                                size={30}/></span></Card.Title>
                            <div className={'cardText'}>
                                {
                                    props.structureFrom.map((field) =>
                                        <Card.Text>
                                            <span className={'subTitle'}>{field.description}: </span> {field.value}
                                        </Card.Text>
                                    )}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <Row className={'stepperBtnSubmit'}>
                    <Col md={{span:5, offset:4}}>
                        <Col md={11}>

                        <SubmitBtn status={props.form.formStatus}
                               create={props.create}
                               update={props.update}
                               resource={props.resource.one}
                    />
                        </Col>
                </Col>
                </Row>
                <div/>
            </div>
        </div>
    )
}

//context
function SubmitBtn(props) {

    let status = props.status;

    switch (status) {
        case "createMode":
            status = <button className="btn btn-outline-success"
                             onClick={props.create}>
                Create {props.resource} <Icon.BagCheck size={15}/>
            </button>
            break;
        case "created":
            status = <div className="bookingCreated">
                Your {props.resource} Created Successfully <Icon.CheckCircle size={25}/>
            </div>
            break;
        case "updateMode":
            status = <button className="btn btn-outline-warning"
                             onClick={props.update}>
                Update {props.resource} <Icon.BagCheck size={15}/>
            </button>
            break;
        case "updated":
            status = <div className="bookingUpdated">
                Your {props.resource} Details Updated Successfully <Icon.CheckCircle size={25}/>
            </div>
            break;
        case "isLoadingCreate":
            status = <Roller color="#70db70" size={30} className={'spinnerSubmit'}/>
            break;
        case "isLoadingUpdate":
            status = <Roller color="#ffff00" size={30} className={'spinnerSubmit'}/>
            break;
        default:
            status = <button className="btn btn-outline-success"
                             onClick={props.create}>
                Create {props.resource} <Icon.BagCheck size={15}/>
            </button>
            break;
    }

    return (
        <div className={'stepperBtnSubmit'}>
            {status}
        </div>
    )
}
