import React, {useState, useEffect} from 'react'

import Container from "react-bootstrap/Container";


import {API, graphqlOperation, Storage} from 'aws-amplify';
import {createMalfunction} from '../../../graphql/mutations';
import {useParams} from "react-router-dom";

import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper';

import 'react-day-picker/lib/style.css';
import '../../../style/Lasers.css';

import NameInput from "../inputElements/NameInput";
import DescriptionInput from "../inputElements/DescriptionInput";
import ImageInput from '../inputElements/ImageInput'
import Submit from "../Submit"
import StepsHeader from "../StepsHeader";
import momentParser from "moment";
import {listLaserTypes, listMedicalClinics} from "../../../graphql/queries";


// TODO: custom hooks + con

let initCreateFormState = {
    name: '',
    serialNumber:'',
    medicalClinic:'',
    description: '',
    date: momentParser(new Date()).format('YYYY-MM-DD'),
    formStatus: 'createMode'

}

const initAppData = {
    clinics: [],
    types:[]
}


export default function LaserTypeControlled(props) {

    let {id, laserType, serialNumber, clinic, typeID} = useParams();

    let initFormState;

    if (laserType && serialNumber && clinic && typeID) {
        initFormState = {
            laserType: laserType,
            serialNumber: serialNumber,
            typeID: typeID,
            medicalClinic: {lat: 30.1933, lng: -85.6588},
            date: momentParser(new Date()).format('YYYY-MM-DD'),
            formStatus: 'createMode'
        }

    } else {
        initFormState = initCreateFormState;
    }


    const [malfunctionForm, setMalfunctionForm] = useState(initFormState)
    const [appData, setAppData] = useState(initAppData)

    const [stepper, setStepper] = useState({})

    const structureForm = [

        {


            description:
                malfunctionForm.formStatus === 'createMode' ? "Creation Date" : "Update Date",
            value: malfunctionForm['date']
        },

        {description: "Laser Name", value: malfunctionForm['laserType']},
        {description: "Serial Number", value: malfunctionForm['serialNumber']},
        {description: "Current Location", value: malfunctionForm['medicalClinic']?.clinicName},
        {description: "Description", value: malfunctionForm['description']},
    ]


    useEffect(() => {
//ref
        setStepper(new Stepper(document.querySelector('#stepper1'),
            {
                linear: false,
                animation: true
            }))

        async function initData() {

            let types = await initTypes();
            let clinics = await initClinics();
            setAppData({...appData, clinics: clinics, types: types});
        }

        initData();
    }, [])

    async function initTypes() {


        try {

            let data = await API.graphql({query: listLaserTypes})

            let types = data.data.listLaserTypes.items

            return types;

        } catch (err) {

            console.log(err)
        }
    }


    async function initClinics() {

        try {
            let data = await API.graphql({query: listMedicalClinics})

            let clinics = data.data.listMedicalClinics.items;
            if (malfunctionForm.formStatus === 'createMode') {
                let updateClinic = clinics.find(medicalClinic => medicalClinic.clinicName === clinic)
                setMalfunctionForm({...malfunctionForm, medicalClinic: updateClinic})


            } else {
                setMalfunctionForm({...malfunctionForm, medicalClinic: clinics[0]})
            }
            return clinics;

        } catch (err) {
            console.log(err)
        }
    }



    async function handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        const id = target.id;


        setMalfunctionForm({...malfunctionForm, [id]: value})
    }




    async function createMalfunctionFun() {

        //TODO: add business logic to server

        setMalfunctionForm({...malfunctionForm, formStatus: 'isLoadingCreate'})

        let malfunctionDTO = {
            laserID: id,
            serviceID: '7a3655a6-484e-4162-ac8d-011c46828fed',
            description: malfunctionForm.description,
        }

        let messageDTO = {
            serialNumber: malfunctionForm.serialNumber,
            laserName:malfunctionForm.laserType,
            clinicName: malfunctionForm.medicalClinic.clinicName,
            address: malfunctionForm.medicalClinic.address,
            description: malfunctionForm.description
        }

        try {
            let result = await API.graphql(graphqlOperation(createMalfunction, {input: malfunctionDTO}))

            let response = await fetch('http://localhost:3000/messages/message/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(messageDTO),
            })

            setMalfunctionForm({...malfunctionForm, formStatus: 'created'})
            console.log(response)
        } catch (err) {
            console.log(err)
        }
    }



    function initForm() {

        setMalfunctionForm(initCreateFormState)
        stepper.reset()
    }

//TODO: use context
    return (
        <Container>
            <div id="stepper1" className="bs-stepper">
                <div>
                    <StepsHeader steps={["Description"]}/>
                    <div className="bs-stepper-content">


                        <DescriptionInput handleInputChange={handleInputChange}
                                          resource={'Malfunction'}
                                          description={malfunctionForm.description}
                                          stepper={stepper}
                        />
                        <Submit form={malfunctionForm}
                                stepper={stepper}
                                resource={{one: 'Malfunction', plural: 'Lasers'}}
                                navOption={"/Lasers"}
                                createMode={false}
                                structureFrom={structureForm}
                                create={createMalfunctionFun}
                                initForm={initForm}/>
                    </div>
                </div>
            </div>
        </Container>
    );

}






