import React, {useState, useEffect} from 'react'

import Container from "react-bootstrap/Container";

import '../style/Card.css'
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";


import * as Icon from "react-bootstrap-icons";
import {API, graphqlOperation, Auth, Storage} from 'aws-amplify';
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Map from './widgets/Map'
import '../style/Clinics.css'


import {listMedicalClinics} from '../graphql/queries';
import {LinkContainer} from "react-router-bootstrap";
import Card from "react-bootstrap/Card";

export default function Clinics(props) {

    const [clinics, setClinics] = useState([])

    useEffect(() => {

        async function fetchData() {

            try {

                //custom resolver
                let clinicsData = await API.graphql(graphqlOperation(listMedicalClinics))

                setClinics(clinicsData.data.listMedicalClinics.items)


            } catch (err) {

                console.log(err)
            }
        }

        fetchData();
    }, [])


    return (
        <Container>
            <Row className={'lasersTop'}>
                <Col md={8}>
                    <h3>Clinics Management</h3>
                </Col>
                <Col md={4}>
                    <LinkContainer to={"/Clinic"}>
                        <Button
                            variant={'outline-success'}
                            size="sm"
                            onClick={''}>
                            New Clinic <Icon.GeoAlt size={15}/>
                        </Button>
                    </LinkContainer>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <Map clinics={clinics} zoom={6}
                         styleMap={'clinicsMap'}
                    />
                </Col>
                <Col className={'clinicList'} md={4}>
                    {clinics.map((clinic) =>
                        <Card className={'item'} border='light'>
                            <div className={'clinicInfo'}>
                                {clinic.clinicName}
                                <div className={'clinicAddress'}>
                                    {clinic.address}
                                </div>

                                <span className={'clinicBtn'}>
                                        <Button variant={"outline-warning"}
                                                size="sm">Update
                                            <Icon.ArrowRepeat size={15}/>
                                        </Button>
                                </span>
                                <span className={'clinicBtn'}>
                                        <Button variant={"outline-danger"} size="sm"
                                            onClick={() => {
                                                    }} className={'leftSpace'}>
                                            Delete <Icon.DashCircle size={15}/>
                                        </Button>
                                    </span>
                            </div>
                        </Card>
                    )}
                </Col>
            </Row>
        </Container>
    )
}

//<ListItems myBookings={myBookings}/>