import React, {useContext} from "react";
import SignInForm from "./signInForm";
import ConfirmSignUpForm from "./confirmSignUpForm";
import SignUpForm from "./signUpForm";
import ResetPasswordForm from "./resetPasswordForm";
import NewPasswordForm from "./newPasswordForm";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import {AuthContext} from "../../../contexts/authContext";



export default function LoginMainView() {

    const {authState} = useContext(AuthContext);

    let loginMainView;

    switch (authState.status) {
        case "signIn":
            loginMainView = <SignInForm/>
            break;
        case "confirmSignUp":
            loginMainView = <ConfirmSignUpForm/>
            break;
        case "signUp":
            loginMainView = <SignUpForm/>
            break;
        case "resetPassword":
            loginMainView = <ResetPasswordForm/>
            break;
            case "newPassword":
            loginMainView = <NewPasswordForm/>
            break;
        default:
            loginMainView = <SignInForm/>
            break;
    }

    return (
        <Col md={{span: 4, offset: 4}}>
             <span className={'logoImg'}>
             <Image
                 src={'https://www.sec.gov/Archives/edgar/data/1494891/000161577419006326/img001_v1.jpg'}/>
             </span>
            <div className={'loginForm form-group'}>
                {loginMainView}
            </div>
        </Col>
    )
}
