import React, {useState, useEffect} from 'react'

import Container from "react-bootstrap/Container";

import '../style/Card.css'
import '../style/Lasers.css'
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

import * as Icon from "react-bootstrap-icons";
import {API, graphqlOperation, Auth, Storage} from 'aws-amplify';
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from './widgets/Modal'

import {Roller} from 'react-spinners-css';


import {listInvoices, listLaserTypes} from '../graphql/queries';
import '../style/Invoices.css'

import Switch from "react-switch";
import momentParser from "moment";

// TODO: use context

export default function Invoices(props) {

    const [companyInvoices, setCompanyInvoices] = useState([])

    useEffect(() => {

        async function fetchData() {

            try {

                //custom resolver
                let invoices = await API.graphql(graphqlOperation(listInvoices))

                let arrInvoices = invoices.data.listInvoices.items


                let mapInvoices = arrInvoices.map((invoice) => {

                    return {
                        companyName: invoice.company.companyName,
                        amount: invoice.amount,
                        invoiceDate: momentParser(invoice.createdAt).format('YYYY-MM-DD'),
                        item: invoice.item,
                        dueDate: invoice.dueDate,
                        unitPrice: invoice.unitPrice,
                        status: invoice.status,
                        quantity: invoice.quantity,
                        description: invoice.description

                    }
                })


                mapInvoices.sort((invoiceA, invoiceB) => {

                    let invoiceADay = new Date(invoiceA.invoiceDate)

                    let invoiceADayValue = momentParser([invoiceADay.getFullYear(), invoiceADay.getMonth(), invoiceADay.getDate()]);
                    let invoiceBDay = new Date(invoiceB.invoiceDate)
                    let invoiceBDayValue = momentParser([invoiceBDay.getFullYear(), invoiceBDay.getMonth(), invoiceBDay.getDate()]);

                    let diffDays = invoiceBDayValue.diff(invoiceADayValue, 'days')
                    return diffDays
                })



                setCompanyInvoices(mapInvoices)

            } catch (err) {

                console.log(err)
            }
        }

        fetchData();
    }, [])


    return (

        <Container>
            <Row className={'lasersTop'}>
                <Col md={6}>
                    <h3>Invoices Management</h3>
                </Col>


            </Row>
            {companyInvoices.length === 0 ?
                <Roller className={'spinner'} color="#4d94ff"/> :
                <>
                    <Container className={'bookingList'}>
                        {/* change to container listBooking and list lasers */}
                        <ListInvoices invoices={companyInvoices}/>

                    </Container>
                </>
            }
        </Container>

    )

}


//TODO: refactor


function ListInvoices(props) {

    const [selectedLaser, setSelectedLaser] = useState(0);


    return (
        props.invoices.map((invoice) =>
            <Card className={'item'} border='light'>
                <Row className={'cardWrapper'}>

                                <span key={invoice.id} className={'invoiceInfo'} border="light">
                                    <Row>
                                        <Col md={10}>
                                        <h5 className={'laserType'}>Sensus Healthcare
                                            ${invoice.amount}</h5>
                                        <div>Service:<span
                                            className={'itemData'}>{invoice.item}</span>
                                        </div>
                                        <div>Invoice Date:<span className={'itemData'}>
                                              {invoice.invoiceDate}</span>
                                        </div>
                                        <div>Due Date:<span
                                            className={'itemData'}>{invoice.dueDate}</span>
                                        </div>
                                        <div>Active Lasers Quantity:<span className={'itemData'}>
                                            {invoice.quantity}</span>

                                        </div>
                                            <div>Unit Price:<span className={'itemData'}>
                                                 ${invoice.unitPrice}</span>

                                        </div>



                                        </Col>
                                        <Col md={2}>
                                            <Row>
                                            <span className={'sensusImg'}>
                                    <Image
                                        src={'https://www.sec.gov/Archives/edgar/data/1494891/000161577419006326/img001_v1.jpg'}/>
                                </span>
                                            </Row>
                                                                <span className={'invoiceBtn'}>
                        <Button
                            variant={'outline-success'}
                            size="sm"
                            onClick={' '}>
                            Pay <Icon.BagCheck size={15}/>
                        </Button>
                    </span>

                                        </Col>

                                    </Row>
                                </span>

                </Row>

            </Card>
        ))
}

