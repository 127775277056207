import React from "react";
import * as Icon from "react-bootstrap-icons";


export default function NameInput(props) {

    return (
        <div id="name" className="content">
            <div className="form-group">
                <div className={'stageInput'}>
                    <label htmlFor="name">{props.resource} Name</label>
                        <input className={'form-control'} id="name"
                               onChange={props.handleInputChange}
                               value={props.name}>
                        </input>
                </div>
                <div className={'stepperBtnFirst'}>
                    <button className="btn btn-outline-primary"
                            onClick={() => props.stepper.next()}>
                        Next <Icon.CaretRight size={15}/>
                    </button>
                </div>
            </div>
        </div>
    )
}