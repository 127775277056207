/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://dfcbawvhtzbefmzsijeoal67wy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:101f6ce4-705c-4b9e-9d57-c2f5f650bb33",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_5ugZqznF2",
    "aws_user_pools_web_client_id": "7pj5dan4glud490capnhsvrovl",
    "oauth": {},
    "aws_user_files_s3_bucket": "sentinel203534-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "laserclient-20210905153444-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2pu3cz3mfca6c.cloudfront.net"
};


export default awsmobile;
