import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import '../../style/Modal.css'
import * as Icon from "react-bootstrap-icons";


export default function ModalWindow(props) {


    return (
        <Modal show={props.showModal}
               onHide={() => props.setShow(!props.showModal)}>
            <Modal.Header closeButton>
                <Modal.Title className={'modalContent'}>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'modalContent'}>
                    {props.body}
                </div>

                <div className={'modalBtn'}>
                    <Button variant={props.btnColor}
                            onClick={props.deleteSelected}>
                        {props.btnText} <Icon.Trash size={15}/>
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}