/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      priority
      a
      createdAt
      updatedAt
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      priority
      a
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      priority
      a
      createdAt
      updatedAt
    }
  }
`;
export const createLaser = /* GraphQL */ `
  mutation CreateLaser(
    $input: CreateLaserInput!
    $condition: ModelLaserConditionInput
  ) {
    createLaser(input: $input, condition: $condition) {
      id
      companyID
      serialNumber
      typeID
      locationID
      activeStatus
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      type {
        id
        companyID
        laserName
        description
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      currentClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLaser = /* GraphQL */ `
  mutation UpdateLaser(
    $input: UpdateLaserInput!
    $condition: ModelLaserConditionInput
  ) {
    updateLaser(input: $input, condition: $condition) {
      id
      companyID
      serialNumber
      typeID
      locationID
      activeStatus
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      type {
        id
        companyID
        laserName
        description
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      currentClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLaser = /* GraphQL */ `
  mutation DeleteLaser(
    $input: DeleteLaserInput!
    $condition: ModelLaserConditionInput
  ) {
    deleteLaser(input: $input, condition: $condition) {
      id
      companyID
      serialNumber
      typeID
      locationID
      activeStatus
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      type {
        id
        companyID
        laserName
        description
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      currentClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMalfunction = /* GraphQL */ `
  mutation CreateMalfunction(
    $input: CreateMalfunctionInput!
    $condition: ModelMalfunctionConditionInput
  ) {
    createMalfunction(input: $input, condition: $condition) {
      id
      laserID
      serviceID
      description
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMalfunction = /* GraphQL */ `
  mutation UpdateMalfunction(
    $input: UpdateMalfunctionInput!
    $condition: ModelMalfunctionConditionInput
  ) {
    updateMalfunction(input: $input, condition: $condition) {
      id
      laserID
      serviceID
      description
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMalfunction = /* GraphQL */ `
  mutation DeleteMalfunction(
    $input: DeleteMalfunctionInput!
    $condition: ModelMalfunctionConditionInput
  ) {
    deleteMalfunction(input: $input, condition: $condition) {
      id
      laserID
      serviceID
      description
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLaserType = /* GraphQL */ `
  mutation CreateLaserType(
    $input: CreateLaserTypeInput!
    $condition: ModelLaserTypeConditionInput
  ) {
    createLaserType(input: $input, condition: $condition) {
      id
      companyID
      laserName
      description
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLaserType = /* GraphQL */ `
  mutation UpdateLaserType(
    $input: UpdateLaserTypeInput!
    $condition: ModelLaserTypeConditionInput
  ) {
    updateLaserType(input: $input, condition: $condition) {
      id
      companyID
      laserName
      description
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLaserType = /* GraphQL */ `
  mutation DeleteLaserType(
    $input: DeleteLaserTypeInput!
    $condition: ModelLaserTypeConditionInput
  ) {
    deleteLaserType(input: $input, condition: $condition) {
      id
      companyID
      laserName
      description
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      companyID
      financeID
      dueDate
      amount
      item
      unitPrice
      quantity
      description
      status
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      finance {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      companyID
      financeID
      dueDate
      amount
      item
      unitPrice
      quantity
      description
      status
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      finance {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      companyID
      financeID
      dueDate
      amount
      item
      unitPrice
      quantity
      description
      status
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      finance {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      companyName
      medicalClinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        nextToken
      }
      types {
        items {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      companyName
      medicalClinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        nextToken
      }
      types {
        items {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      companyName
      medicalClinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        nextToken
      }
      types {
        items {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMedicalClinic = /* GraphQL */ `
  mutation CreateMedicalClinic(
    $input: CreateMedicalClinicInput!
    $condition: ModelMedicalClinicConditionInput
  ) {
    createMedicalClinic(input: $input, condition: $condition) {
      id
      companyID
      clinicName
      address
      country
      state
      lat
      lng
      operationID
      serviceID
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMedicalClinic = /* GraphQL */ `
  mutation UpdateMedicalClinic(
    $input: UpdateMedicalClinicInput!
    $condition: ModelMedicalClinicConditionInput
  ) {
    updateMedicalClinic(input: $input, condition: $condition) {
      id
      companyID
      clinicName
      address
      country
      state
      lat
      lng
      operationID
      serviceID
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMedicalClinic = /* GraphQL */ `
  mutation DeleteMedicalClinic(
    $input: DeleteMedicalClinicInput!
    $condition: ModelMedicalClinicConditionInput
  ) {
    deleteMedicalClinic(input: $input, condition: $condition) {
      id
      companyID
      clinicName
      address
      country
      state
      lat
      lng
      operationID
      serviceID
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      companyID
      firstName
      lastName
      email
      phone
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      companyID
      firstName
      lastName
      email
      phone
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      companyID
      firstName
      lastName
      email
      phone
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOperation = /* GraphQL */ `
  mutation CreateOperation(
    $input: CreateOperationInput!
    $condition: ModelOperationConditionInput
  ) {
    createOperation(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      deliveries {
        items {
          id
          fromClinicID
          bookingID
          operationID
          drivingTime
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOperation = /* GraphQL */ `
  mutation UpdateOperation(
    $input: UpdateOperationInput!
    $condition: ModelOperationConditionInput
  ) {
    updateOperation(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      deliveries {
        items {
          id
          fromClinicID
          bookingID
          operationID
          drivingTime
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOperation = /* GraphQL */ `
  mutation DeleteOperation(
    $input: DeleteOperationInput!
    $condition: ModelOperationConditionInput
  ) {
    deleteOperation(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      deliveries {
        items {
          id
          fromClinicID
          bookingID
          operationID
          drivingTime
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createManager = /* GraphQL */ `
  mutation CreateManager(
    $input: CreateManagerInput!
    $condition: ModelManagerConditionInput
  ) {
    createManager(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateManager = /* GraphQL */ `
  mutation UpdateManager(
    $input: UpdateManagerInput!
    $condition: ModelManagerConditionInput
  ) {
    updateManager(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteManager = /* GraphQL */ `
  mutation DeleteManager(
    $input: DeleteManagerInput!
    $condition: ModelManagerConditionInput
  ) {
    deleteManager(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFinance = /* GraphQL */ `
  mutation CreateFinance(
    $input: CreateFinanceInput!
    $condition: ModelFinanceConditionInput
  ) {
    createFinance(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFinance = /* GraphQL */ `
  mutation UpdateFinance(
    $input: UpdateFinanceInput!
    $condition: ModelFinanceConditionInput
  ) {
    updateFinance(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFinance = /* GraphQL */ `
  mutation DeleteFinance(
    $input: DeleteFinanceInput!
    $condition: ModelFinanceConditionInput
  ) {
    deleteFinance(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      laserID
      medicalClinicID
      customerID
      startDate
      endDate
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      medicalClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      laserID
      medicalClinicID
      customerID
      startDate
      endDate
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      medicalClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      laserID
      medicalClinicID
      customerID
      startDate
      endDate
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      medicalClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDelivery = /* GraphQL */ `
  mutation CreateDelivery(
    $input: CreateDeliveryInput!
    $condition: ModelDeliveryConditionInput
  ) {
    createDelivery(input: $input, condition: $condition) {
      id
      fromClinicID
      bookingID
      operationID
      drivingTime
      booking {
        id
        laserID
        medicalClinicID
        customerID
        startDate
        endDate
        laser {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        medicalClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        customer {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fromClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDelivery = /* GraphQL */ `
  mutation UpdateDelivery(
    $input: UpdateDeliveryInput!
    $condition: ModelDeliveryConditionInput
  ) {
    updateDelivery(input: $input, condition: $condition) {
      id
      fromClinicID
      bookingID
      operationID
      drivingTime
      booking {
        id
        laserID
        medicalClinicID
        customerID
        startDate
        endDate
        laser {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        medicalClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        customer {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fromClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDelivery = /* GraphQL */ `
  mutation DeleteDelivery(
    $input: DeleteDeliveryInput!
    $condition: ModelDeliveryConditionInput
  ) {
    deleteDelivery(input: $input, condition: $condition) {
      id
      fromClinicID
      bookingID
      operationID
      drivingTime
      booking {
        id
        laserID
        medicalClinicID
        customerID
        startDate
        endDate
        laser {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        medicalClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        customer {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fromClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
