import React from "react";
import LaserImage from "../../widgets/LaserImage";
import * as Icon from "react-bootstrap-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default function LaserInput(props) {

    return (
        <div id="laserType" className="content">
            <div className="form-group">
                <Row>
                    <Col md={5}>
                        <div className={'laserInput'}>
                            <label htmlFor="laserType">Select Laser Type</label>
                            <select className={'form-control'} id="laserType"
                                    onChange={props.handleInputChange}
                                    value={props.selectedLaserType}>
                                {props.types.map((laserType) =>

                                    <option value={laserType.laserName}>{laserType.laserName}</option>
                                )}
                            </select>
                            {props.types.length?
                                <>
                                    <div
                                        className={'laserTitle'}>{' ' + props.types.find((laserType) => laserType.laserName === props.selectedLaserType).laserName + ' '}Indications
                                    </div>
                                    <div
                                        className={'laserText'}>{props.types.find((laserType) => laserType.laserName === props.selectedLaserType).description}
                                    </div>
                                </>
                                :
                                <>
                                </>
                            }
                        </div>


                    </Col>
                    <Col md={{span: 4, offset: 2}}>
                        <div className={'laserBookingImg'}>
                            <LaserImage laserUrl={props.selectedImgUrl}/>
                        </div>
                    </Col>
                </Row>
                <div className={'stepperBtnFirst'}>
                    <button className="btn btn-outline-primary"
                            onClick={() => props.stepper.next()}>
                        Next <Icon.CaretRight size={15}/>
                    </button>
                </div>
            </div>
        </div>
    )
}


