import React, {useState, useEffect, useReducer} from 'react'

import Container from "react-bootstrap/Container";

import '../style/Card.css'
import Card from "react-bootstrap/Card";
import * as Icon from "react-bootstrap-icons";
import {API, graphqlOperation, Storage} from "aws-amplify";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import momentParser from 'moment';

import {getDeliveries, listLaserTypes} from '../graphql/queries';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import '../style/Operations.css'
import {Roller} from "react-spinners-css";
import {LinkContainer} from "react-router-bootstrap";


const initialStateFilters = {deliveryDate: '', toMedicalClinic: ''}

export default function Operations() {

    const [myDeliveries, setDeliveries] = useState({init: [], byFilter: []})
    const [filterDate, setFilterDate] = useState(false)
    const [filterClinic, setFilterClinic] = useState(false)
    const [stateFilters, setStateFilters] = useState(initialStateFilters)
    const [clinics, setClinics] = useState([])

    useEffect(() => {

        async function fetchData() {

            try {

                console.log(process.env.NODE_ENV)

                //custom resolver
                let deliveriesData = await API.graphql(graphqlOperation(getDeliveries))

                let arrDeliveries = deliveriesData.data.listDeliverys.items

                let data = await API.graphql({query: listLaserTypes})

                let types = data.data.listLaserTypes.items;
                let deliveries = arrDeliveries.map((delivery) => {

                    let laserName = types.find((type)=> type.id === delivery.booking.laser.typeID).laserName

                    let status = getBookingStatus(delivery.booking.startDate)
                    return {
                        id: delivery.id,
                        laserType: laserName,
                        orderDate: delivery.booking.createdAt.substring(0, 10),
                        deliveryDate: delivery.booking.startDate,
                        toClinic: delivery.booking.medicalClinic.clinicName,
                        fromClinic: delivery.fromClinic.clinicName,
                        serialNumber: delivery.booking.laser.serialNumber,
                        status: status,
                        drivingTime: delivery.drivingTime,
                        bookingID: delivery.bookingID
                    }
                })



                //refactor TODO custom hook
                let images = await Storage.list('f4f21382-9179-4da0-9630-8919a203950d/')
                let arrImg = [];

                for (const img of images) {
                    let imgUrl = await Storage.get(img.key);
                    //let imgUrl = await Storage.get(img.key, {expires: 60});
                    const typeLaser = types.find(type => 'f4f21382-9179-4da0-9630-8919a203950d/'+type.id === img.key);


                    arrImg.push({key: img.key, laserName:typeLaser.laserName,  imgUrl: imgUrl})
                }


                //refactor TODO custom hook
                deliveries.sort((deliveryA, deliveryB) => {

                    let deliveryADay = new Date(deliveryA.deliveryDate)

                    let deliveryADayValue = momentParser([deliveryADay.getFullYear(), deliveryADay.getMonth(), deliveryADay.getDate()]);
                    let deliveryBDay = new Date(deliveryB.deliveryDate)
                    let deliveryBDayValue = momentParser([deliveryBDay.getFullYear(), deliveryBDay.getMonth(), deliveryBDay.getDate()]);

                    let diffDays = deliveryADayValue.diff(deliveryBDayValue, 'days')
                    return diffDays
                })

                deliveries.map((delivery) => {


                    let img = arrImg.find(img => img.laserName === delivery.laserType)
                    delivery.imgUrl = img.imgUrl;

                })
                setDeliveries({init: deliveries, byFilter: deliveries})

            } catch (err) {

                console.log(err)
            }
        }

        fetchData();
    }, [])

    function getBookingStatus(bookingDeliveryDate) {

        const toDay = new Date();

        const toDayMoment = momentParser([toDay.getFullYear(), toDay.getMonth(), toDay.getDate()]);

        const deliveryDate = new Date(bookingDeliveryDate);

        const deliveryDateMoment = momentParser([deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate()]);

        const numOfDays = deliveryDateMoment.diff(toDayMoment, 'days') // 1

        if (numOfDays <= 7 && numOfDays >= 0) {

            return numOfDays + ' Days'
        } else if (numOfDays >= 8) {

            return 'Pending'
        } else
            return 'Delivered'
    }


    useEffect(() => {

        let filterBookings = [...myDeliveries.init];

        for (const propKey in stateFilters) {

            if (stateFilters[propKey] !== '') {

                filterBookings = filterBookings.filter(booking => booking[propKey] === stateFilters[propKey])
            }
        }
        setDeliveries({init: myDeliveries.init, byFilter: filterBookings})
    }, [stateFilters])

// custom hook: TODO
    function getDeliveryStatus(bookingDeliveryDate) {

        const toDay = new Date();

        const toDayMoment = momentParser([toDay.getFullYear(), toDay.getMonth(), toDay.getDate()]);

        const deliveryDate = new Date(bookingDeliveryDate);

        const deliveryDateMoment = momentParser([deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate()]);

        const numOfDays = deliveryDateMoment.diff(toDayMoment, 'days') // 1

        if (numOfDays <= 7) {

            return numOfDays + ' Days'
        } else return 'Pending'
    }


    return (

        <Container>
            <Row>
                <div className={'operationsTop'}>
                    <div className={'operationTitle'}>
                        <h3>Lasers Deliveries</h3>
                    </div>
                    <span className={'operationsTopButtonsFilters'}>
                            <Button className={'filterButton'} size="sm"
                                    variant={filterDate ? 'primary' : 'outline-primary'}
                                    onClick={() => {
                                        setFilterDate(!filterDate)
                                        setStateFilters({...stateFilters, ['deliveryDate']: ''})
                                    }}>
                                {filterDate ? 'Cancel Date Filter' : 'Filter By Date'}
                            </Button>
                            <Button className={'filterButton'} size="sm"
                                    variant={filterClinic ? 'primary' : 'outline-primary'}
                                    onClick={() => {
                                        setFilterClinic(!filterClinic)
                                        setStateFilters({...stateFilters, ['toMedicalClinic']: ''})
                                    }}>
                                {filterClinic ? 'Cancel Clinic Filter' : 'Filter By Clinic'}
                            </Button>
                    </span>
                    <span className={'operationsFilters'}>
                            {filterDate &&
                            <DayPickerInput className={'filterDate'}
                                            onDayChange={(day) => {
                                                setStateFilters({
                                                    ...stateFilters,
                                                    ['deliveryDate']: momentParser(day).format('YYYY-MM-DD')
                                                })

                                                //setFilter('deliveryDate', momentParser(day).format('YYYY-MM-DD'))
                                            }}/>
                            }
                        {filterClinic &&
                        <select className={'filterClinic'} id="medicalClinic" placeholder={'Select Clinic'}
                                onChange={(event) => {
                                    setStateFilters({...stateFilters, ['toMedicalClinic']: event.target.value})

                                    //setFilter('toMedicalClinic', event.target.value)
                                }}>
                            {clinics.map((item) =>
                                <option key={item.toString()}>{item.clinicName}</option>
                            )}
                        </select>}
                    </span>
                </div>
            </Row>
            <Container className={'bookingList'}>
                {myDeliveries.byFilter.length === 0 ?
                    <Roller className={'spinner'} color="#4d94ff"/> :
                    <Container className={'bookingList'}>
                        <ListDeliveries
                            deliveries={myDeliveries.byFilter.filter(booking => booking.status !== 'Delivered')}/>
                    </Container>
                }
            </Container>
        </Container>

    )

}

function getBookingStatus(bookingDeliveryDate) {

    const toDay = new Date();

    const toDayMoment = momentParser([toDay.getFullYear(), toDay.getMonth(), toDay.getDate()]);

    const deliveryDate = new Date(bookingDeliveryDate);

    const deliveryDateMoment = momentParser([deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate()]);

    const numOfDays = deliveryDateMoment.diff(toDayMoment, 'days') // 1

    if (numOfDays <= 7 && numOfDays >= 0) {

        return numOfDays + ' Days'
    } else if (numOfDays >= 8) {

        return 'Pending'
    } else
        return 'Delivered'
}

function LaserStatus(props) {

    let status;

    if (props.status === 'Pending') {
        status = <span className={'bookingPending'}>{props.status}<Icon.BagDash size={25}/></span>;
    } else {
        status = <span className={'bookingInProcess'}>{props.status}<Icon.BagCheck size={25}/></span>;

    }

    return (
        <div className={'bookingStatus'}>
            {status}
        </div>
    )
}


function ListDeliveries(props) {

    return (
        props.deliveries.map((delivery) =>
            <Card className={'item'} border='light'>
                <Row className={'cardWrapper'}>
                                <span className={'laserImg'}>
                                    <Image src={delivery.imgUrl}/>
                                </span>
                    <span key={delivery.toString()} className={'cardInfo'} border="light">
                                    <Row>
                                        <Col md={5}>
                                        <h5>{delivery.laserType}</h5>
                                        <div>Delivery Date:<span
                                            className={'itemData'}>{delivery.deliveryDate}</span>
                                    </div>
                                     <div>Serial Number:<span
                                         className={'itemData'}>{delivery.serialNumber}</span>
                                    </div>
                                    <div>From:<span
                                        className={'itemData'}>{delivery.fromClinic}</span>
                                    </div>
                                    <div>To:<span
                                        className={'itemData'}>{delivery.toClinic}</span>
                                    </div>

                                    <div>Driving Time:<span
                                        className={'itemData'}>{delivery.drivingTime}</span>
                                    </div>

                                        </Col>
                                        <Col md={{span: 7}}>
                                            <Row>
                                                <LaserStatus status={delivery.status}/>
                                            </Row>
                                        <Col>
                                                <Row className={'bookingBtns'}>
                                                        <Button variant={"outline-success"}
                                                                size="sm">Approve Delivery
                                                            <Icon.BagCheck size={15}/>
                                                        </Button>
                                                        <LinkContainer to={"/Delivery/" + delivery.id + '/' +delivery.laserType + '/' +delivery.deliveryDate + '/' + delivery.serialNumber + '/' + delivery.toClinic+ '/'+ delivery.fromClinic +'/'+ delivery.drivingTime+'/'+ delivery.bookingID}>
                                                            <Button variant={"outline-warning"}
                                                                    size="sm">Update Details
                                                                <Icon.ArrowRepeat size={15}/>
                                                            </Button>
                                                        </LinkContainer>
                                                </Row>
                                        </Col>
                                        </Col>
                                    </Row>
                                </span>
                </Row>
            </Card>
        ))
}

