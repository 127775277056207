import React, {useEffect} from "react";
import {AmplifyChatbot} from "@aws-amplify/ui-react";


import {Widget, addResponseMessage, addLinkSnippet, addUserMessage} from "react-chat-widget";
import 'react-chat-widget/lib/styles.css';


function Chat (props) {

    useEffect(() => {

        addResponseMessage('Welcome to Sentinel Laser chat!');
    }, []);

    const handleNewUserMessage = (newMessage) => {

        console.log(`New message incoming! ${newMessage}`);
        // Now send the message throught the backend API
    };


    return (
            <Widget
                handleNewUserMessage={handleNewUserMessage}
                title="Sentinel Laser Chat"
                subtitle="Here for help on any topic"
            />
    );

}

export default Chat;
