import React, {useContext} from "react";
import {
    NavLink,

} from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Container from 'react-bootstrap/Container';

import logo from '../assets/lasers-logo.png'
import {AuthContext} from "../contexts/authContext";
import * as Icon from "react-bootstrap-icons";


//: TODO: static header and scroll

const AppNavbar = () => {

    let {authState, signOut} = useContext(AuthContext);

    return (
        <div className={'navBarTop'}>
            <Navbar expand="lg">
                <Navbar.Brand href="/">
                    <img
                    src={logo}
                    width="300"
                    height="60"
                    className="d-inline-block align-top"
                />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" >
                    <Nav className="mr-auto" >
                        <NavLink  to="/Booking" className="nav-link"  activeClassName="selected">Booking</NavLink >
                        <NavLink to="/Activity" className="nav-link" activeClassName="selected">Activity</NavLink>
                        <NavLink to="/Statistics" className="nav-link" activeClassName="selected">Statistics</NavLink>
                        <NavLink to="/Users" className="nav-link" activeClassName="selected">Users</NavLink>
                        <NavLink to="/Accounting" className="nav-link" activeClassName="selected">Accounting</NavLink>
                        <NavLink to="/Operations" className="nav-link" activeClassName="selected">Operations</NavLink>
                        <NavLink to="/Lasers" className="nav-link" activeClassName="selected">Lasers</NavLink>
                        <NavLink to="/Invoices" className="nav-link" activeClassName="selected">Invoices</NavLink>
                        <NavLink to="/Clinics" className="nav-link" activeClassName="selected">Clinics</NavLink>
                        <NavLink to="/Service" className="nav-link" activeClassName="selected">Service</NavLink>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <Icon.PersonCircle size={25} />
                    <NavDropdown title={authState?.user?.email?.replace('.com', '')} className={'dropDown'}>
                        <NavDropdown.ItemText className={'pointer'}>User: {authState?.user?.userName}</NavDropdown.ItemText>
                        <NavDropdown.Divider />
                        <NavDropdown.ItemText href="#action/3.2" className={'pointer'}>Company: {authState?.user?.company}</NavDropdown.ItemText>
                        <NavDropdown.ItemText href="#action/3.2" className={'pointer'}>Level: Admin</NavDropdown.ItemText>
                        <NavDropdown.ItemText href="#action/3.3" className={'pointer'}>Settings</NavDropdown.ItemText>
                        <NavDropdown.ItemText href="#action/3.3" className={'pointer'}>My Activity</NavDropdown.ItemText>
                        <NavDropdown.Divider />
                        <NavDropdown.ItemText onClick={signOut} className={'pointer'}>Sign Out</NavDropdown.ItemText>
                    </NavDropdown>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default AppNavbar;
