import React, { useCallback, useMemo, useState } from "react";
import * as Icon from "react-bootstrap-icons";

import { useDropzone } from 'react-dropzone';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const baseStyle = {
    marginTop:20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '60px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    transition: 'border .3s ease-in-out',
    height: 150
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};





export default function ImageInput(props) {

    const [file, setFile] = useState({});

    const onDrop = useCallback(acceptedFile => {
        setFile({
            file: acceptedFile[0],
            preview: URL.createObjectURL(acceptedFile[0])

        });

        props.handleInputImage(acceptedFile[0])

    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png'
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div id="img" className="content">
            <div className="form-group">
                <div className={'imgInput'}>
                    <label htmlFor="img">{props.resource} Image</label>

                    <Row>
                        <Col md={5}>
                            <div {...getRootProps({style})}>
                                <input {...getInputProps()} id="imgFile"
                                />
                                <div>Drag and drop your images here.</div>
                            </div>
                        </Col>
                        <Col md={{ span: 4, offset: 3 }}>
                            <div key={file.name}>
                                <img className={'myImg'}
                                     src={file.preview}

                                />
                            </div>
                        </Col>
                    </Row>

                </div>
                <div className={'stepperBtnClinic'}>
                    <button className="btn btn-outline-secondary"
                            onClick={() => props.stepper.previous()}>
                        <Icon.CaretLeft size={17}/> Previous
                    </button>
                    <button className="btn btn-outline-primary"
                            onClick={() => props.stepper.next()}>
                        Next <Icon.CaretRight size={15}/>
                    </button>
                </div>
            </div>
        </div>

    )
}

/*

<input  id="imgFile" type="file"
                           onChange={props.handleInputChange}
                           >
                    </input>

                    <Col md={{ span: 5, offset: 1 }}>
                        <div className={'laserBookingImg'}>
                            <LaserImage laserUrl={props.file}/>
                        </div>
                    </Col>
 */