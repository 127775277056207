// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Todo, Laser, Booking, MedicalClinic, Company, User, LaserType, Invoice, Finance, Operation, Delivery, Service, Malfunction, Customer, Manager, Post, Comment } = initSchema(schema);

export {
  Todo,
  Laser,
  Booking,
  MedicalClinic,
  Company,
  User,
  LaserType,
  Invoice,
  Finance,
  Operation,
  Delivery,
  Service,
  Malfunction,
  Customer,
  Manager,
  Post,
  Comment
};