import React, {useState, useEffect} from 'react'

import Container from "react-bootstrap/Container";
import '../style/Card.css'
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import * as Icon from "react-bootstrap-icons";
import {API, graphqlOperation, Auth, Storage} from 'aws-amplify';
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from './widgets/Modal'

import {Roller} from 'react-spinners-css';

import {listLaserTypes, listBookings, deliveryByBookingID} from '../graphql/queries';
import {deleteBooking, deleteDelivery} from '../graphql/mutations';
import momentParser from "moment";
import {LinkContainer} from "react-router-bootstrap";

//TODO : fetch by key sort  date of bookings

export default function Activity(props) {

    const [myBookings, setBookings] = useState([])
    const [historyState, setHistoryState] = useState(false)

    useEffect(() => {

        async function fetchData() {

            try {

                //custom resolver
                let bookingsData = await API.graphql(graphqlOperation(listBookings))

                let arrBookings = bookingsData.data.listBookings.items

                let data = await API.graphql({query: listLaserTypes})

                let types = data.data.listLaserTypes.items;

                let bookings = arrBookings.map((booking) => {

                    let laserName = types.find((type)=> type.id === booking.laser.typeID).laserName

                    let status = getBookingStatus(booking.startDate)

                    return {
                        id: booking.id,
                        laserType: laserName,
                        orderDate: booking.createdAt.substring(0, 10),
                        startDate: booking.startDate,
                        endDate: booking.endDate,
                        medicalClinic: booking.medicalClinic.clinicName,
                        status: status
                    }
                })



                let images = await Storage.list('f4f21382-9179-4da0-9630-8919a203950d/')
                // You can await here
                let arrImg = [];

                for (const img of images) {
                    let imgUrl = await Storage.get(img.key);
                    //let imgUrl = await Storage.get(img.key, {expires: 60});

                    const typeLaser = types.find(type => 'f4f21382-9179-4da0-9630-8919a203950d/'+type.id === img.key);


                    arrImg.push({key: img.key, laserName:typeLaser.laserName,  imgUrl: imgUrl})
                }


                //refactor TODO
                bookings.sort((bookingA, bookingB) => {

                    let bookingADay = new Date(bookingA.startDate)

                    let bookingADayValue = momentParser([bookingADay.getFullYear(), bookingADay.getMonth(), bookingADay.getDate()]);
                    let bookingBDay = new Date(bookingB.startDate)
                    let bookingBDayValue = momentParser([bookingBDay.getFullYear(), bookingBDay.getMonth(), bookingBDay.getDate()]);

                    let diffDays = bookingADayValue.diff(bookingBDayValue, 'days')
                    return diffDays
                })

                bookings.map((booking) => {


                    let img = arrImg.find(img => img.laserName === booking.laserType)
                    booking.imgUrl = img.imgUrl;

                })
                setBookings(bookings)

            } catch (err) {

                console.log(err)
            }
        }

        fetchData();
    }, [])

    // custom hook: TODO
    function getBookingStatus(bookingDeliveryDate) {

        const toDay = new Date();

        const toDayMoment = momentParser([toDay.getFullYear(), toDay.getMonth(), toDay.getDate()]);

        const deliveryDate = new Date(bookingDeliveryDate);

        const deliveryDateMoment = momentParser([deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate()]);

        const numOfDays = deliveryDateMoment.diff(toDayMoment, 'days') // 1

        if (numOfDays <= 7 && numOfDays >= 0) {

            return numOfDays + ' Days'
        } else if (numOfDays >= 8) {

            return 'Pending'
        } else
            return 'Delivered'
    }

    async function deleteSelectedBooking(bookingID){

        let bookingDTO= {
            id: bookingID
        }

        let deliveryData = await API.graphql({query: deliveryByBookingID, variables: {bookingID: bookingID}})

        const deliveryID = deliveryData.data.listDeliverys.items[0].id;

        let deliveryDTO= {
            id: deliveryID
        }

        try{
            let resultDelivery = await API.graphql(graphqlOperation(deleteDelivery, {input: deliveryDTO}))

            let resultBooking = await API.graphql(graphqlOperation(deleteBooking, {input: bookingDTO}))
            setBookings(myBookings.filter(booking => booking.id !== bookingID))
            console.log(resultBooking)
        }
        catch(err){
            console.log(err)
        }
    }


    return (

        <Container>
            <Button className={'top'}
                    variant={historyState ? 'outline-primary' : 'primary'}
                    onClick={() => {
                        setHistoryState(false)
                    }}>
                Future Orders
            </Button>
            <Button className={'top'}
                    variant={historyState ? 'primary' : 'outline-primary'}
                    onClick={() => {
                        setHistoryState(true)
                    }}>
                Order History
            </Button>
            {myBookings.length === 0 ?
                <Roller className={'spinner'} color="#4d94ff"/> :
                <Container className={'bookingList'}>
                    {historyState ?
                        <ListBookings bookings={myBookings.filter(booking => booking.status === 'Delivered')}/> :
                        <ListBookings bookings={myBookings.filter(booking => booking.status !== 'Delivered')}
                            deleteSelected={deleteSelectedBooking}/>
                    }
                </Container>
            }
        </Container>

    )

}


function LaserStatus(props) {

    let status;

    if (props.status === 'Delivered') {
        status = <span className={'bookingDelivered'}>Delivered<Icon.CheckCircle size={25}/></span>;
    } else if (props.status === 'Pending') {
        status = <span className={'bookingPending'}>Pending<Icon.BagDash size={25}/></span>;
    } else {
        status = <span className={'bookingInProcess'}>{props.status}<Icon.BagCheck size={25}/></span>;
    }

    return (
        <div className={'bookingStatus'}>
            {status}
        </div>
    )
}

//TODO: refactor


function ListBookings(props) {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(0);


    async function deleteSelected(){

        await props.deleteSelected(selectedBooking)
        setShowDeleteModal(!showDeleteModal)

    }
//use containment

    // TODO: use keys to improve performance

    // useReducer remove state management logic from the component


    return (
        props.bookings.map((booking) =>
            <Card className={'item'} border='light'>
                <Row className={'cardWrapper'}>
                                <span className={'laserImg'}>
                                    <Image src={booking.imgUrl}/>
                                </span>
                                <span key={booking.toString()} className={'cardInfo'} border="light">
                                    <Row>
                                        <Col md={4}>
                                        <h5 className={'laserType'}>{booking.laserType}</h5>
                                        <div>Order Date:<span className={'itemData'}>{booking.orderDate}</span></div>
                                        <div>Medical Clinic:<span
                                            className={'itemData'}>{booking.medicalClinic}</span></div>
                                        <div>Start Date:<span className={'itemData'}>{booking.startDate}</span></div>
                                        <div>End Date:<span className={'itemData'}>{booking.endDate}</span></div>
                                        </Col>
                                        <Col md={{span: 7, offset: 1}}>
                                            <Row>
                                                <LaserStatus status={booking.status}/>
                                            </Row>
                                            {booking.status !== 'Delivered' ?
                                                <Row className={'bookingBtns'}>
                                                    <LinkContainer
                                                        to={"/Booking/" + booking.id + '/' + booking.laserType + '/' + booking.startDate + '/' + booking.endDate + '/' + booking.medicalClinic}>
                                                        <Button variant={"outline-warning"}
                                                                size="sm">Update Details
                                                            <Icon.ArrowRepeat size={15}/>
                                                        </Button>
                                                    </LinkContainer>
                                                    <Button variant={"outline-danger"} size="sm"
                                                            onClick={() => {
                                                                setSelectedBooking(booking.id)
                                                                setShowDeleteModal(!showDeleteModal)
                                                            }}>
                                                        Delete <Icon.DashCircle size={15}/>
                                                    </Button>
                                                    <Modal  showModal={showDeleteModal}
                                                            setShow={setShowDeleteModal}
                                                            deleteSelected={deleteSelected}
                                                            title={'Delete Booking'}
                                                            body={'Are you sure you want to delete the booking?'}
                                                            btnColor={'outline-danger'}
                                                            btnText={'Delete'}
                                                    />
                                                </Row> : <></>}
                                        </Col>
                                    </Row>
                                </span>

                </Row>

            </Card>
        ))
}

//<ListItems myBookings={myBookings}/>
