import React, {useState, useEffect} from 'react'

import Container from "react-bootstrap/Container";


import {API, graphqlOperation} from 'aws-amplify';
import {updateBooking,updateDelivery} from '../../../graphql/mutations';
import {listMedicalClinics} from '../../../graphql/queries';
import {useParams} from "react-router-dom";

import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper';

import 'react-day-picker/lib/style.css';
import '../../../style/Lasers.css';

import ClinicInput from "../inputElements/ClinicInput";
import SerialNumberInput from '../inputElements/SerialNumberInput'
import StepsHeader from "../StepsHeader";

import Submit from "../Submit"
import momentParser from "moment";


// TODO: custom hooks + con


const initAppData = {
    clinics: []
}


export default function DeliveryControlled(props) {

    let {id,laserType, deliveryDate, serialNumber, fromClinic, toClinic, drivingTime, bookingID} = useParams();

    let initFormState;

    if (serialNumber && fromClinic && toClinic) {
        initFormState = {
            serialNumber: serialNumber,
            laserType: laserType,
            deliveryDate: deliveryDate,
            fromClinic: {lat: 30.1933, lng: -85.6590},
            toClinic: {lat: 41.850033, lng: -87.6500523},
            drivingTime: drivingTime,
            date: momentParser(new Date()).format('YYYY-MM-DD'),
            formStatus: 'updateMode'
        }
    }


    const [deliveryForm, setDeliveryForm] = useState(initFormState)
    const [appData, setAppData] = useState(initAppData)
    const [stepper, setStepper] = useState({})

    const structureForm = [

        {description: "Update Date", value: deliveryForm['date']},
        {description: "Laser Type", value: deliveryForm['laserType']},
        {description: "Serial Number", value: deliveryForm['serialNumber']},
        {description: "Delivery Date", value: deliveryForm['deliveryDate']},
        {description: "From Clinic", value: deliveryForm['fromClinic'].clinicName},
        {description: "To Clinic", value: deliveryForm['toClinic'].clinicName},
        {description: "Driving Time", value: deliveryForm['drivingTime']},
    ]


    useEffect(() => {
//ref
        setStepper(new Stepper(document.querySelector('#stepper1'),
            {
                linear: false,
                animation: true
            }))

        async function initData() {

            let clinics = await initClinics();
            setAppData({clinics: clinics});
        }

        initData();
    }, [])

    async function initClinics() {

        try {
            let data = await API.graphql({query: listMedicalClinics})

            let clinics = data.data.listMedicalClinics.items;
            let fromClinicData = clinics.find(medicalClinic => medicalClinic.clinicName === fromClinic)
            let toClinicData = clinics.find(medicalClinic => medicalClinic.clinicName === toClinic)
            setDeliveryForm({...deliveryForm, fromClinic: fromClinicData, toClinic:toClinicData})

            return clinics;

        } catch (err) {
            console.log(err)
        }
    }

    function updateDrivingTime(drivingTime){

        setDeliveryForm({...deliveryForm, ['drivingTime']: drivingTime})
    }


    async function handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        let id = target.id;

        if (id === 'medicalClinic') {
            value = appData.clinics.find(clinic => clinic.clinicName === value)
            id= 'fromClinic';
        }
        setDeliveryForm({...deliveryForm, [id]: value})
    }

    async function updateDeliveryFun() {

        //TODO: add business logic to server

        setDeliveryForm({...deliveryForm, formStatus: 'isLoadingUpdate'})



        let bookingDTO = {
            id: bookingID,
            laserID: '',
        }

        let deliveryDTO ={
            id: id,
            fromClinicID: deliveryForm.fromClinic.id,
            drivingTime: deliveryForm.drivingTime
        }

        try {
            //await API.graphql(graphqlOperation(updateBooking, {input: bookingDTO}))
            let result = await API.graphql(graphqlOperation(updateDelivery, {input: deliveryDTO}))

            setDeliveryForm({...deliveryForm, formStatus: 'updated'})
            console.log(result)
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <Container>
            <div id="stepper1" className="bs-stepper">
                <div>
                    <StepsHeader steps={["Clinic", "SerialNumber"]}/>
                    <div className="bs-stepper-content">
                        <ClinicInput handleInputChange={handleInputChange}
                                     clinics={appData.clinics}
                                     toMedicalClinic={deliveryForm.toClinic}
                                     selectedMedicalClinic={deliveryForm.fromClinic}
                                     stepper={stepper}
                                     route={'true'}
                                     updateDrivingTime={updateDrivingTime}/>
                        <SerialNumberInput handleInputChange={handleInputChange}
                                           selectOptions={true}
                                           stepper={stepper}
                                           selectedSerialNumber={deliveryForm.serialNumber}/>
                        <Submit     form={deliveryForm}
                                    stepper={stepper}
                                    resource={{one:'Delivery',plural: 'Deliveries'}}
                                    navOption={"/Operations"}
                                    createMode={false}
                                    structureFrom={structureForm}
                                    update={updateDeliveryFun}
                                    />


                    </div>
                </div>
            </div>
        </Container>
    );

}


//TODO: one handle submit






