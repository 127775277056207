import React, {useState, useEffect, useContext} from "react";
import {API, Auth} from 'aws-amplify';
import LoginMainView from "./view_components/loginMainView";
import {actionCreators} from '../../state_management/actions/authActions'
import {AuthContext} from "../../contexts/authContext";
import formValidation from "../../utils/formValidation"
import './login.css'

const formInputState = {
    username: '',
    password: '',
};

const formDictionaryTypes = {
    username: 'text',
    password: 'text',
    phone: 'phone',
    company: 'text',
    email: 'email',
    verificationCode: 'text'
};

export default function LoginContainer() {

    //:TODO send emails from an SES verified identity in production

    const [loginForm, setLoginForm] = useState(formInputState)
    const {authState, dispatch} = useContext(AuthContext);

    //use callback or use memo
    function onChange(e) {
        if(e?.target){
            setLoginForm({...loginForm, [e.target.id]: e.target.value});
        }
        else{
            setLoginForm({...loginForm, 'phone': e});
        }
    }

    async function signUp() {

        let formValidation = isFormValid(['username','password','phone','company','email'])

        if (formValidation.valid) {
            dispatch(actionCreators.signIn(true, ''))
            try {
                await Auth.signUp({
                    username: loginForm.username,
                    password: loginForm.password,
                    attributes: {
                        email: loginForm.email,
                        phone_number: loginForm.phone,
                        'custom:company': loginForm.company
                    }
                })
                dispatch(actionCreators.confirmSignUp(false, ''))
            } catch (err) {
                dispatch(actionCreators.signUp(false, {message: err.message}))
            }
        } else {
            dispatch(actionCreators.signUp(false, formValidation.error))
        }
    }

    async function signedIn(){

        try{
            dispatch(actionCreators.signedIn(true, '', {}))
            const user = await Auth.currentAuthenticatedUser();
            localStorage['authentication'] = true
            localStorage['userName'] = user.username
            dispatch(actionCreators.signedIn(false, '', {
                userName: user.username,
                email: user.attributes.email,
                company: user.attributes['custom:company']
            }))
        }
        catch(err){
            dispatch(actionCreators.signedIn(false, err, {}))
        }
    }

    async function confirmSignUp() {

        let formValidation = isFormValid(['username','verificationCode'])

        if (formValidation.valid) {
            dispatch(actionCreators.confirmSignUp(true, ''))
            try {
                await Auth.confirmSignUp(loginForm.username, loginForm.verificationCode);
                await Auth.signIn(loginForm.username, loginForm.password);
                await signedIn();

            } catch (err) {
                dispatch(actionCreators.confirmSignUp(false, {message: err.message}))
            }
        }
        else{
            dispatch(actionCreators.confirmSignUp(false, formValidation.error))
        }
    }

    async function signIn() {

        let formValidation = isFormValid(['username','password'])

        if (formValidation.valid) {
            dispatch(actionCreators.signIn(true, ''))
            try {
                await Auth.signIn(loginForm.username, loginForm.password);
                await signedIn();
            } catch (err) {
                dispatch(actionCreators.signIn(false, {message: 'Username or password incorrect'}))
            }
        }
        else {
            dispatch(actionCreators.signIn(false, formValidation.error))
        }
    }

    async function resetPassword() {

        let formValidation = isFormValid(['username'])

        if (formValidation.valid) {
            dispatch(actionCreators.resetPassword(true, ''))
            try {
                let data = await Auth.forgotPassword(loginForm.username)
                dispatch(actionCreators.newPassword(false, ''))
            } catch (err) {
                dispatch(actionCreators.resetPassword(false, {message: err.message}))
            }
        }
        else{
            dispatch(actionCreators.resetPassword(false, formValidation.error))
        }
    }

    async function confirmNewPassword() {

        let formValidation = isFormValid(['username','password','verificationCode'])

        if (formValidation.valid) {
            dispatch(actionCreators.newPassword(true, ''))
            try {
                let data = await Auth.forgotPasswordSubmit(loginForm.username, loginForm.verificationCode, loginForm.password)
                dispatch(actionCreators.signIn(false, ""))
            } catch (err) {
                dispatch(actionCreators.newPassword(false, {message: err.message}))
            }
        }
        else{
            dispatch(actionCreators.newPassword(false, formValidation.error))
        }
    }

    function isFormValid(formFields) {

        let formCheckResult = {valid: true, error: {}}
        for (const field of formFields) {
            let checkFieldResult = formValidation(loginForm[field], formDictionaryTypes[field])

            if (!checkFieldResult.valid) {
                formCheckResult.error = {...formCheckResult.error, [field]: checkFieldResult.errorDescription}
                formCheckResult.valid = false;
            }
        }
        return formCheckResult;
    }

    let loginControlled = {
        signUp: signUp,
        confirmSignUp: confirmSignUp,
        signIn: signIn,
        resetPassword: resetPassword,
        confirmNewPassword: confirmNewPassword,
        loginForm: loginForm,
        dispatch: dispatch,
        authState: authState,
        onChange: onChange,
    }

    return (
        <AuthContext.Provider value={loginControlled}>
            <LoginMainView/>
        </AuthContext.Provider>
    )
}
