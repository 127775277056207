import React, {useEffect, useState} from 'react'
import GoogleMapReact from 'google-map-react';

import '../../style/map.css'

import Image from "react-bootstrap/Image";
import * as Icon from "react-bootstrap-icons";


const Marker = (props) =>
    <>
        {
            props.clinics.length > 1 ?
                <div className={'clinicMarker'}>
                    <Image className={'iconClinic'}
                           src={"https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png"}/>
                </div>
                : <div className={'mapMarker'}>
                    <Image className={'imgMarker'}
                           src={"https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png"}/>
                </div>
        }
    </>

export default function Map(props) {

    const [mapMarkerPosition, setMarker] = useState({
        lat: props.lat,
        lng: props.lng
    })

    const [mapsState, setMapsState] = useState({
            map: undefined,
            maps: undefined
        }
    )

    const [mapsRouterMarkers, setRouterMarkers] = useState({
            fromMarker: {lat: props.lat, lng: props.lng},
            toMarker: {lat: props.toLat, lng: props.toLng}
        }
    )

    const [mapsDirections, setMapsDirections] = useState({
            directionsService: undefined,
            directionsRenderer: undefined
        }
    )

    const [mapDirectionsData, setDirectionsData] = useState({
            distance: '',
            drivingTime: ''
        }
    )

    function route() {

        if (!mapsDirections.directionsService && !mapsDirections.directionsRenderer) {
            setMapsDirections({

                directionsService: new window.google.maps.DirectionsService(),
                directionsRenderer: new window.google.maps.DirectionsRenderer()
            })
        } else {
            try {


                mapsDirections.directionsRenderer.setMap(null);
                mapsDirections.directionsRenderer.setMap(mapsState.map);


                // Existing map object displays directions
                // Create route from existing points used for markers
                const route = {
                    origin: new window.google.maps.LatLng(mapsRouterMarkers.fromMarker.lat, mapsRouterMarkers.fromMarker.lng),
                    destination: new window.google.maps.LatLng(mapsRouterMarkers.toMarker.lat, mapsRouterMarkers.toMarker.lng),
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    drivingOptions: {
                        departureTime: new Date(Date.now()),  // for the time N milliseconds from now.
                        trafficModel: 'optimistic'
                    }
                }

                mapsDirections.directionsService.route(route,
                    function (response, status) { // anonymous function to capture directions
                        if (status !== 'OK') {
                            window.alert('Directions request failed due to ' + status);
                            return;
                        } else {
                            //setDirections(response)

                            mapsDirections.directionsRenderer.setDirections(response); // Add route to the map
                            var directionsData = response.routes[0].legs[0];
                            setDirectionsData({
                                distance: directionsData.distance.text,
                                drivingTime: directionsData.duration.text
                            })
                            props.updateDrivingTime(directionsData.duration.text)
                            // Get data about the mapped route
                            if (!directionsData) {
                                window.alert('Directions request failed');
                                return;
                            } else {
                            }
                        }
                    });
            } catch (err) {
                console.log(err)
            }

        }

    }

    useEffect(() => {

        if (mapsState.map && mapsState.maps) {

            route();

        }


    }, [mapsDirections.directionsRenderer, mapsDirections.directionsService])

    useEffect(() => {

        if (mapsState.map && mapsState.maps) {

            route();
        }

    }, [mapsRouterMarkers.toMarker, mapsRouterMarkers.fromMarker])

    useEffect(() => {

        if (props.route) {

            setRouterMarkers({
                toMarker: {lat: props.toLat, lng: props.toLng},
                fromMarker: {lat: props.lat, lng: props.lng}
            })
        } else {
            setMarker({
                lat: props.lat,
                lng: props.lng
            })
        }
    }, [props.lat, props.lng])




    const handleApiLoaded = (map, maps) => {

        setMapsState({map: map, maps: maps})

        //let directionsRenderer = new window.google.maps.DirectionsRenderer();

        //directionsRenderer.setMap(map); // Existing map object displays directions
    };


    function getMapCenter(clinics, lat, lng) {

        if (clinics) {

            if (clinics[0]) {
                return clinics[0]
            } else {
                return {lat: 26.40011, lng: -80.10371}
            }
        } else {
            return {lat: lat, lng: lng}
        }
    }

    return (
        <div>
            <div className={props.styleMap}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: 'AIzaSyCn8nbnKTeFpF3GkVv-KddKV0MSBdaFI_Q'}}
                    center={getMapCenter(props.clinics, props.toLat, props.toLng)}
                    zoom={props.zoom ? props.zoom : 7}
                    zoomControlOptions={'google.maps.ControlPosition.RIGHT_TOP'}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
                >
                    {
                        props.route ?
                            <></> :
                            props.clinics.map((marker) =>
                                <Marker
                                    lat={marker.lat}
                                    lng={marker.lng}
                                    text="My Marker"
                                    clinics={props.clinics}
                                />
                            )

                    }
                </GoogleMapReact>

                {
                    props.route ?
                        <div className={'routeInfo'}>
                            <span className={'bold'}>Driving Time:</span> {mapDirectionsData.drivingTime}
                            <sapn className={'distanceInfo'}>
                                <sapn className={'bold'}>Distance: </sapn>
                                 {mapDirectionsData.distance}</sapn>
                        </div> :
                        <></>
                }
            </div>
        </div>
    );

}


