import React from "react";
import * as Icon from "react-bootstrap-icons";
import momentParser from "moment";
import Card from "react-bootstrap/Card";
import {LinkContainer} from "react-router-bootstrap";
import {Roller} from "react-spinners-css";


export default function SubmitInvoice(props) {

    return (
        <div id="submit" className="content">
            <div className="form-group">
                <div className={'stepperBtnSubmit'}>
                    {props.invoiceForm.formStatus === 'createMode' ||  props.invoiceForm.formStatus === 'updateMode'?
                        <></>
                        :
                        <div className="bookingCreatedBtn">
                            <button className="btn btn-outline-primary"
                                    onClick={props.initForm}>
                                Create Another Invoice <Icon.BagPlus size={17}/>
                            </button>
                            <LinkContainer to={"/Home"}>
                                <button className="btn btn-outline-secondary"
                                        onClick={''}>
                                    Go To The Menu <Icon.HouseDoor size={17}/>
                                </button>
                            </LinkContainer>
                            <LinkContainer to={"/Invoices"}>
                                <button className="btn btn-outline-info"
                                        onClick={''}>
                                    View Your Invoices <Icon.CartCheck size={17}/>
                                </button>
                            </LinkContainer>

                        </div>
                    }
                </div>
                <div>
                    <Card className={'bookingDetails'}>
                        <Card.Body>
                            <Card.Title><span className={'bookingDetailsTitle'}>Invoice Details <Icon.BagCheck size={30}/></span></Card.Title>
                            <div className={'cardText'}>
                                <Card.Text>
                                <span
                                    className={'subTitle'}>Company Name: </span> {props.invoiceForm.customer.DisplayName}
                                </Card.Text>
                                <Card.Text>
                                    <span className={'subTitle'}>Service for number of active lasers: </span>{props.invoiceForm.activeLasers}
                                </Card.Text>
                                <Card.Text>
                                    <span className={'subTitle'}>Payment for laser service: </span>1500$
                                </Card.Text>
                                <Card.Text>
                                    <span className={'subTitle'}>Invoice date:</span> {momentParser(new Date()).format('YYYY-MM-DD')}
                                </Card.Text>
                                <Card.Text>
                                    <span
                                        className={'subTitle'}>Due date:</span> {momentParser(new Date(props.invoiceForm.invoiceDate.getFullYear(),props.invoiceForm.invoiceDate.getMonth()+1,props.invoiceForm.invoiceDate.getDate()-1)).format('YYYY-MM-DD')}
                                </Card.Text>
                                <Card.Text>
                                    <span
                                        className={'subTitle'}>Total Payment: </span>{props.invoiceForm.activeLasers*1500}$
                                </Card.Text>

                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className={'stepperBtnSubmit'}>
                    <Submit status={props.invoiceForm.formStatus}
                            createInvoice={props.createInvoice}/>
                </div>
                <div/>
            </div>
        </div>
    )
}

//context
function Submit(props) {

    let status = props.status;

    switch (status) {
        case "createMode":
            status = <button className="btn btn-outline-success"
                             onClick={props.createInvoice}>
                Create Invoice <Icon.BagCheck size={15}/>
            </button>
            break;
        case "invoiceCreated":
            status = <div className="bookingCreated">
                Your Invoice Created Successfully <Icon.CheckCircle size={35}/>
            </div>
            break;
        case "updateMode":
            status = <button className="btn btn-outline-warning"
                             onClick={props.createInvoice}>
                Update Order <Icon.BagCheck size={15}/>
            </button>
            break;
        case "bookingUpdated":
            status = <div className="bookingUpdated">
                Your Order Updated Successfully <Icon.CheckCircle size={35}/>
            </div>
            break;
        case "isLoadingCreate":
            status = <Roller color="#70db70" size={30}/>
            break;
        case "isLoadingUpdate":
            status = <Roller color="#ffff00" size={30}/>
            break;
        default:
            status = <button className="btn btn-outline-success"
                             onClick={props.createInvoice}>
                Create Invoice <Icon.BagCheck size={15}/>
            </button>
            break;
    }

    return (
        <div className={'stepperBtnSubmit'}>
            {status}
        </div>
    )
}

