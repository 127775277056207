import React, {useState, useEffect} from 'react'

import Container from "react-bootstrap/Container";


import {API, graphqlOperation,Storage} from 'aws-amplify';
import {createMedicalClinic, updateMedicalClinic} from '../../../graphql/mutations';
import {useParams} from "react-router-dom";

import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper';

import 'react-day-picker/lib/style.css';
import '../../../style/Lasers.css';
import nodeGeocoder from 'node-geocoder'


import LocationInput from "../inputElements/LocationInput";
import NameInput from '../inputElements/NameInput';
import Submit from "../Submit"
import StepsHeader from "../StepsHeader";
import momentParser from "moment";


// TODO: custom hooks + con

let initCreateFormState = {
    name: '',
    address: '',
    country: '',
    state:'',
    lat: 0,
    lng:0,
    date: momentParser(new Date()).format('YYYY-MM-DD'),
    formStatus: 'createMode'

}

export default function ClinicControlled(props) {

    let {id, clinicName, address, country, stateClinic, lat, lng} = useParams();

    let initFormState;

    if (id && clinicName && address && country && stateClinic && lat && lng) {
        initFormState = {
            name: clinicName,
            address: address,
            country: country,
            state: stateClinic,
            lat: lat,
            lng: lng,
            date: momentParser(new Date()).format('YYYY-MM-DD'),
            formStatus: 'updateMode'
        }

    } else {
        initFormState = initCreateFormState;
    }


    const [clinicForm, setClinicForm] = useState(initFormState)
    const [stepper, setStepper] = useState({})

    const structureForm = [

        {description:
                clinicForm.formStatus === 'createMode'? "Creation Date":"Update Date",
            value: clinicForm['date']},

        {description: "Clinic Name", value: clinicForm['name']},
        {description: "Address", value: clinicForm['address']},

    ]


    useEffect(() => {
//ref
        setStepper(new Stepper(document.querySelector('#stepper1'),
            {
                linear: false,
                animation: true
            }))
    }, [])

    async function handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        const id = target.id;

        setClinicForm({...clinicForm, [id]: value})
    }

    async function handleInputLocation(address,lat, lng) {


        setClinicForm({...clinicForm, address: address,lat: lat, lng:lng})
    }



    async function createClinicFun() {

        //TODO: add business logic to server

        setClinicForm({...clinicForm, formStatus: 'isLoadingCreate'})

        let clinicDTO = {
            clinicName: clinicForm.name,
            companyID: 'f4f21382-9179-4da0-9630-8919a203950d',
            address: clinicForm.address,
            country: clinicForm.country,
            state: clinicForm.state,
            operationID:'8a2274d4-5e56-463c-b7fd-9a402dc9243c',
            lat: clinicForm.lat,
            lng: clinicForm.lng

        }

        try {
            let result = await API.graphql(graphqlOperation(createMedicalClinic, {input: clinicDTO}))
            setClinicForm({...clinicForm, formStatus: 'created'})
            console.log(result)
        } catch (err) {
            console.log(err)
        }
    }

    async function updateClinicFun() {

        //TODO: add business logic to server

        setClinicForm({...clinicForm, formStatus: 'isLoadingUpdate'})

        let clinicDTO = {
            id: id,
            clinicName: clinicForm.name,
            companyID: 'f4f21382-9179-4da0-9630-8919a203950d',
            address: clinicForm.address,
            country: clinicForm.country,
            state: clinicForm.state,
            serviceID: '7a3655a6-484e-4162-ac8d-011c46828fed',
            operationID:'8a2274d4-5e56-463c-b7fd-9a402dc9243c',
            lat: clinicForm.lat,
            lng: clinicForm.lng
        }

        try {
            let result = await API.graphql(graphqlOperation(updateMedicalClinic, {input: clinicDTO}))
            setClinicForm({...clinicForm, formStatus: 'updated'})
            console.log(result)
        } catch (err) {
            console.log(err)
        }
    }

    function initForm() {

        setClinicForm(initCreateFormState)
        stepper.reset()
    }

//TODO: use context
    return (
        <Container>
            <div id="stepper1" className="bs-stepper">
                <div>
                    <StepsHeader steps={["Name","Location"]}/>
                    <div className="bs-stepper-content">
                        <NameInput handleInputChange={handleInputChange}
                                   resource={'Clinic'}
                                   name={clinicForm.name}
                                    stepper={stepper}/>
                        <LocationInput handleInputLocation={handleInputLocation}
                                       resource={'Clinic'}
                                       address={clinicForm.address}
                                       stepper={stepper}
                                     />
                        <Submit      form={clinicForm}
                                     stepper={stepper}
                                     resource={{one:'Clinic',plural: 'Clinics'}}
                                     navOption={"/Clinics"}
                                     createMode={true}
                                     structureFrom={structureForm}
                                     create={createClinicFun}
                                     update={updateClinicFun}
                                     initForm={initForm}/>
                    </div>
                </div>
            </div>
        </Container>
    );

}






