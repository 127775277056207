/* src/App.js */
import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import CardColumns from "react-bootstrap/CardColumns";
import * as Icon from 'react-bootstrap-icons';
import Row from "react-bootstrap/Row";
import {LinkContainer} from 'react-router-bootstrap'
import '../style/Home.css'

export default function Home() {

    return (
        <Container>
            <Row className={'homeTitle'}>
                <h4>Management Dashboard</h4>
            </Row>
            <CardColumns>
                <LinkContainer to={"/Booking"}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Booking<Icon.Calendar3 size={25}/></Card.Title>
                            <Card.Text>
                                Order laser to your medical clinic by laser type and date range.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </LinkContainer>
                <LinkContainer to={"/Activity"}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Activity<Icon.PersonCircle size={25}/></Card.Title>
                            <Card.Text>
                                View your last laser orders by dates and clinics.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </LinkContainer>
                <LinkContainer to={"/Statistics"}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Statistics<Icon.BarChart size={25}/></Card.Title>
                            <Card.Text>
                                View statistics on your laser orders with parameters of laser type and data range.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </LinkContainer>
            </CardColumns>
            <CardColumns>
                <LinkContainer to={"/Lasers"}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Lasers<Icon.LayoutTextWindowReverse size={25}/></Card.Title>
                            <Card.Text>
                                Manage your company's lasers, create new lasers, and update laser details. </Card.Text>
                        </Card.Body>
                    </Card>
                </LinkContainer>
                <LinkContainer to={"/Users"}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Users<Icon.People size={25}/></Card.Title>
                            <Card.Text>
                                Manage your company's users and invite new users. </Card.Text>
                        </Card.Body>
                    </Card>
                </LinkContainer>
                <LinkContainer to={"/Invoices"}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Invoices<Icon.CreditCard2Front size={25}/></Card.Title>
                            <Card.Text>
                                View your company's latest invoices for lasers bookings. </Card.Text>
                        </Card.Body>
                    </Card>
                </LinkContainer>
            </CardColumns>
            <CardColumns>
                <LinkContainer to={"/Clinics"}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Clinics<Icon.GeoAlt size={25}/></Card.Title>
                            <Card.Text>
                                Manage your company's clinics, create new clinic, and update clinic details.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </LinkContainer>

                <LinkContainer to={"/Accounting"}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Accounting<Icon.WalletFill size={25}/></Card.Title>
                            <Card.Text>
                                Accounting management and issuing a monthly invoice for laser services.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </LinkContainer>
                <LinkContainer to={"/Operations"}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Operations<Icon.BagCheck size={25}/></Card.Title>
                            <Card.Text>
                                View customer laser order dates and prepare to make your deliveries. </Card.Text>
                        </Card.Body>
                    </Card>
                </LinkContainer>
            </CardColumns>
            <CardColumns>
                <LinkContainer to={"/Service"}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Service<Icon.Gear size={25}/></Card.Title>
                            <Card.Text>
                                View customer lasers malfunctions and update that the repair has been made. </Card.Text>
                        </Card.Body>
                    </Card>
                </LinkContainer>
                <LinkContainer to={"/Company"}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Company<Icon.HouseDoor size={25}/></Card.Title>
                            <Card.Text>
                                View your company details and update the logo. </Card.Text>
                        </Card.Body>
                    </Card>
                </LinkContainer>
            </CardColumns>
        </Container>
    )
}
