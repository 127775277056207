const types = {
    signIn: 'signIn',
    signedIn: 'signedIn',
    signOut: 'signOut',
    confirmSignUp: 'confirmSignUp',
    signUp: 'signUp',
    resetPassword: 'resetPassword',
    newPassword: 'newPassword'
}

export const actionCreators = {
    signIn: (loading, error) => ({
        type: types.signIn,
        payload: {loading, error},
    }),
    signedIn: (loading, error, user) => ({
        type: types.signedIn,
        payload: {loading, error, user},
    }),
    signOut: (loading, error) => ({
        type: types.signOut,
        payload: {loading, error},
    }),
    confirmSignUp: (loading, error) => ({
        type: types.confirmSignUp,
        payload: {loading, error},
    }),
    signUp: (loading, error) => ({
        type: types.signUp,
        payload: {loading, error},
    }),
    resetPassword: (loading, error) => ({
        type: types.resetPassword,
        payload: {loading, error},
    }),
    newPassword: (loading, error) => ({
        type: types.newPassword,
        payload: {loading, error},
    }),
}
