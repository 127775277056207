import React, {useContext} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Roller} from "react-spinners-css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import * as Icon from "react-bootstrap-icons";
import {AuthContext} from "../../../contexts/authContext";
import {actionCreators} from '../../../state_management/actions/authActions'


export default function SignInForm() {

    const {dispatch, authState, loginForm, onChange ,signIn} = useContext(AuthContext);
    let error = authState.error;

    return (
        <>
            <Form noValidate validated={error !== ""} >
                <div className={'title'}>Sign in to your account</div>
                <Form.Group controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        value={loginForm.username}
                        placeholder="username"
                        type="text"
                        maxLength="30"
                        required
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error.username}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        value={loginForm.password}
                        type="password"
                        required
                        placeholder="Password"
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error.password}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Label className={'changeStateMessageLabel'}>Forgot your password?
                    <span className={'changeStateMessage'} onClick={() => {
                        dispatch(actionCreators.resetPassword(false, ""))
                    }}>Reset Password</span>
                </Form.Label>
                {authState.loading ?
                    <></>
                    :
                    <Button variant="outline-success" onClick={signIn}>
                        Sign In <Icon.ShieldCheck size={20}/>
                    </Button>
                }
            </Form>
            <Form.Label className={'changeStateMessageLabel'}>No Account?
                <span className={'changeStateMessage'} onClick={() => {
                    dispatch(actionCreators.signUp(false, ""))
                }}>Create Account</span>
            </Form.Label>
            {authState.loading ?
                <Row>
                    <Col md={{span: 4, offset: 4}}>
                        <Roller color="#70db70" size={30} className={'spinnerSubmit'}/>
                    </Col>
                </Row> :
                <></>
            }
            {error?.message === '' ?
                <></>:
                <Row>
                    <Col  className={'loginError'}>
                        {error.message}
                    </Col>
                </Row>

                }
        </>

    )
}
