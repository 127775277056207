import React from "react";
import Table from 'react-bootstrap/Table';
import Container from "react-bootstrap/Container";
import '../style/Table.css';

export default class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myBookings: [
                {email: 'omri@sensusmd.com', name: 'Omri Hanam',lastLogin: '2021-01-10 05:50'},
                {email: 'yonatan@sensusmd.com', name: 'Yonatan Vainer',lastLogin: '2020-12-20 06:30'},
                {email: 'yuval@sensusmd.com', name: 'Yuval Starnes',lastLogin: '2021-01-15 03:30'},



            ]
        };

        this.listItems = this.state.myBookings.map((booking) =>
            <tr key={booking.toString()}>
                <td>{booking.email}</td>
                <td>{booking.name}</td>
                <td>{booking.lastLogin}</td>
            </tr>

        );



    }


    render() {
        return (
            <Container>

                <div className={'bookingTable'}>
                    <h4>Users Managements</h4>
                    <Container>

                        <button className="btn btn-outline-success" >Invite New User</button>

                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Email</th>
                                <th>User Name</th>
                                <th>Last Login</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.listItems}
                            </tbody>
                        </Table>
                    </Container>
                </div>
            </Container>

        );
    }
}

/*
import React, {lazy, Suspense} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import Login from '../../pages/login'
import AppNavbar from "./Navbar";

import Home from '../Home'

import Bookings from '../Bookings'
import Operations from "../Operations";
import Invoices from "../Invoices";
import Statistics from "../Statistics";
import Lasers from "../Lasers";
import LaserControlled from "../forms/controlled/LaserControlled";
import ClinicControlled from "../forms/controlled/ClinicControlled";
import LaserTypeControlled from "../forms/controlled/LaserTypeControlled";
import MalfunctionControlled from "../forms/controlled/MalfunctionControlled";
import DeliveryForm from "../forms/controlled/DeliveryControlled";
import Activity from "../Activity";
import Accounting from "../Accounting";
import Users from "../Users";
import Clinics from "../Clinics"
import Service from "../Service";
/*
const Bookings = lazy(() => import('../Bookings'));
const Activity = lazy(() => import('../Activity'));
const Lasers = lazy(() => import('../Lasers'));
const LaserControlled = lazy(() => import('../forms/controlled/LaserControlled'));
const LaserTypeControlled = lazy(() => import('../forms/controlled/LaserTypeControlled'));
const Service = lazy(() => import('../Service'));
const Operations = lazy(() => import('../Operations'));
const Clinics = lazy(() => import('../Clinics'));
const ClinicControlled = lazy(() => import('../forms/controlled/ClinicControlled'));
const Invoices = lazy(() => import('../Invoices'));
const Statistics = lazy(() => import('../Statistics'));
const DeliveryForm = lazy(() => import('../forms/controlled/DeliveryControlled'));
const MalfunctionControlled = lazy(() => import('../forms/controlled/MalfunctionControlled'));

            <Suspense fallback={<></>}>



// TODO: use dynamic import or react lazy and context

const AppRouter = () => {

    return (
        <Router>
            <AppNavbar/>
            <Suspense fallback={<></>}>
                <Switch>
                    <Route path="/Login">
                        <Login/>
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                    <Route path="/Home">
                        <Home/>
                    </Route>
                    <Route path="/Booking/:id?/:laserType?/:startDate?/:endDate?/:clinic?">
                        <Bookings/>
                    </Route>
                    <Route path="/Statistics">
                        <Statistics/>
                    </Route>
                    <Route path="/Accounting">
                        <Accounting/>
                    </Route>
                    <Route path="/Activity">
                        <Activity/>
                    </Route>
                    <Route path="/Operations">
                        <Operations/>
                    </Route>
                    <Route path="/Delivery/:id?/:laserType?/:deliveryDate?/:serialNumber?/:toClinic?/:fromClinic?/:drivingTime?/:bookingID?">
                        <DeliveryForm/>
                    </Route>
                    <Route path="/Lasers">
                        <Lasers/>
                    </Route>
                    <Route path="/LaserType/:id?/:laserName?/:description?/:imgUrl?">
                        <LaserTypeControlled/>
                    </Route>
                    <Route path="/Malfunction/:id?/:serialNumber?/:laserType?/:clinic?/:typeID?">
                        <MalfunctionControlled/>
                    </Route>
                    <Route path="/Laser/:id?/:serialNumber?/:laserType?/:clinic?/:typeID?">
                        <LaserControlled/>
                    </Route>
                    <Route path="Users">
                        <Users/>
                    </Route>
                    <Route path="/Clinics">
                        <Clinics/>
                    </Route>
                    <Route path="/Invoices">
                        <Invoices/>
                    </Route>
                    <Route path="/Service">
                        <Service/>
                    </Route>
                </Switch>
            </Suspense>
        </Router>
    );
}

export default AppRouter;

*/


