/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTodo = /* GraphQL */ `
  subscription OnCreateTodo {
    onCreateTodo {
      id
      name
      description
      priority
      a
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTodo = /* GraphQL */ `
  subscription OnUpdateTodo {
    onUpdateTodo {
      id
      name
      description
      priority
      a
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTodo = /* GraphQL */ `
  subscription OnDeleteTodo {
    onDeleteTodo {
      id
      name
      description
      priority
      a
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLaser = /* GraphQL */ `
  subscription OnCreateLaser {
    onCreateLaser {
      id
      companyID
      serialNumber
      typeID
      locationID
      activeStatus
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      type {
        id
        companyID
        laserName
        description
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      currentClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLaser = /* GraphQL */ `
  subscription OnUpdateLaser {
    onUpdateLaser {
      id
      companyID
      serialNumber
      typeID
      locationID
      activeStatus
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      type {
        id
        companyID
        laserName
        description
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      currentClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLaser = /* GraphQL */ `
  subscription OnDeleteLaser {
    onDeleteLaser {
      id
      companyID
      serialNumber
      typeID
      locationID
      activeStatus
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      type {
        id
        companyID
        laserName
        description
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      currentClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMalfunction = /* GraphQL */ `
  subscription OnCreateMalfunction {
    onCreateMalfunction {
      id
      laserID
      serviceID
      description
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMalfunction = /* GraphQL */ `
  subscription OnUpdateMalfunction {
    onUpdateMalfunction {
      id
      laserID
      serviceID
      description
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMalfunction = /* GraphQL */ `
  subscription OnDeleteMalfunction {
    onDeleteMalfunction {
      id
      laserID
      serviceID
      description
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLaserType = /* GraphQL */ `
  subscription OnCreateLaserType {
    onCreateLaserType {
      id
      companyID
      laserName
      description
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLaserType = /* GraphQL */ `
  subscription OnUpdateLaserType {
    onUpdateLaserType {
      id
      companyID
      laserName
      description
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLaserType = /* GraphQL */ `
  subscription OnDeleteLaserType {
    onDeleteLaserType {
      id
      companyID
      laserName
      description
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInvoice = /* GraphQL */ `
  subscription OnCreateInvoice {
    onCreateInvoice {
      id
      companyID
      financeID
      dueDate
      amount
      item
      unitPrice
      quantity
      description
      status
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      finance {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInvoice = /* GraphQL */ `
  subscription OnUpdateInvoice {
    onUpdateInvoice {
      id
      companyID
      financeID
      dueDate
      amount
      item
      unitPrice
      quantity
      description
      status
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      finance {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInvoice = /* GraphQL */ `
  subscription OnDeleteInvoice {
    onDeleteInvoice {
      id
      companyID
      financeID
      dueDate
      amount
      item
      unitPrice
      quantity
      description
      status
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      finance {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      companyName
      medicalClinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        nextToken
      }
      types {
        items {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      companyName
      medicalClinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        nextToken
      }
      types {
        items {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      companyName
      medicalClinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        nextToken
      }
      types {
        items {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMedicalClinic = /* GraphQL */ `
  subscription OnCreateMedicalClinic {
    onCreateMedicalClinic {
      id
      companyID
      clinicName
      address
      country
      state
      lat
      lng
      operationID
      serviceID
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMedicalClinic = /* GraphQL */ `
  subscription OnUpdateMedicalClinic {
    onUpdateMedicalClinic {
      id
      companyID
      clinicName
      address
      country
      state
      lat
      lng
      operationID
      serviceID
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMedicalClinic = /* GraphQL */ `
  subscription OnDeleteMedicalClinic {
    onDeleteMedicalClinic {
      id
      companyID
      clinicName
      address
      country
      state
      lat
      lng
      operationID
      serviceID
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      service {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      lasers {
        items {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      companyID
      firstName
      lastName
      email
      phone
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      companyID
      firstName
      lastName
      email
      phone
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      companyID
      firstName
      lastName
      email
      phone
      company {
        id
        companyName
        medicalClinics {
          nextToken
        }
        users {
          nextToken
        }
        types {
          nextToken
        }
        lasers {
          nextToken
        }
        invoices {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      bookings {
        items {
          id
          laserID
          medicalClinicID
          customerID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOperation = /* GraphQL */ `
  subscription OnCreateOperation {
    onCreateOperation {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      deliveries {
        items {
          id
          fromClinicID
          bookingID
          operationID
          drivingTime
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOperation = /* GraphQL */ `
  subscription OnUpdateOperation {
    onUpdateOperation {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      deliveries {
        items {
          id
          fromClinicID
          bookingID
          operationID
          drivingTime
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOperation = /* GraphQL */ `
  subscription OnDeleteOperation {
    onDeleteOperation {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      deliveries {
        items {
          id
          fromClinicID
          bookingID
          operationID
          drivingTime
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateManager = /* GraphQL */ `
  subscription OnCreateManager {
    onCreateManager {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateManager = /* GraphQL */ `
  subscription OnUpdateManager {
    onUpdateManager {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteManager = /* GraphQL */ `
  subscription OnDeleteManager {
    onDeleteManager {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFinance = /* GraphQL */ `
  subscription OnCreateFinance {
    onCreateFinance {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFinance = /* GraphQL */ `
  subscription OnUpdateFinance {
    onUpdateFinance {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFinance = /* GraphQL */ `
  subscription OnDeleteFinance {
    onDeleteFinance {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      invoices {
        items {
          id
          companyID
          financeID
          dueDate
          amount
          item
          unitPrice
          quantity
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateService = /* GraphQL */ `
  subscription OnCreateService {
    onCreateService {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateService = /* GraphQL */ `
  subscription OnUpdateService {
    onUpdateService {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteService = /* GraphQL */ `
  subscription OnDeleteService {
    onDeleteService {
      id
      user {
        id
        companyID
        firstName
        lastName
        email
        phone
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      malfunctions {
        items {
          id
          laserID
          serviceID
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      clinics {
        items {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBooking = /* GraphQL */ `
  subscription OnCreateBooking {
    onCreateBooking {
      id
      laserID
      medicalClinicID
      customerID
      startDate
      endDate
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      medicalClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBooking = /* GraphQL */ `
  subscription OnUpdateBooking {
    onUpdateBooking {
      id
      laserID
      medicalClinicID
      customerID
      startDate
      endDate
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      medicalClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBooking = /* GraphQL */ `
  subscription OnDeleteBooking {
    onDeleteBooking {
      id
      laserID
      medicalClinicID
      customerID
      startDate
      endDate
      laser {
        id
        companyID
        serialNumber
        typeID
        locationID
        activeStatus
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        type {
          id
          companyID
          laserName
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        malfunctions {
          nextToken
        }
        createdAt
        updatedAt
      }
      medicalClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDelivery = /* GraphQL */ `
  subscription OnCreateDelivery {
    onCreateDelivery {
      id
      fromClinicID
      bookingID
      operationID
      drivingTime
      booking {
        id
        laserID
        medicalClinicID
        customerID
        startDate
        endDate
        laser {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        medicalClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        customer {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fromClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDelivery = /* GraphQL */ `
  subscription OnUpdateDelivery {
    onUpdateDelivery {
      id
      fromClinicID
      bookingID
      operationID
      drivingTime
      booking {
        id
        laserID
        medicalClinicID
        customerID
        startDate
        endDate
        laser {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        medicalClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        customer {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fromClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDelivery = /* GraphQL */ `
  subscription OnDeleteDelivery {
    onDeleteDelivery {
      id
      fromClinicID
      bookingID
      operationID
      drivingTime
      booking {
        id
        laserID
        medicalClinicID
        customerID
        startDate
        endDate
        laser {
          id
          companyID
          serialNumber
          typeID
          locationID
          activeStatus
          createdAt
          updatedAt
        }
        medicalClinic {
          id
          companyID
          clinicName
          address
          country
          state
          lat
          lng
          operationID
          serviceID
          createdAt
          updatedAt
        }
        customer {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fromClinic {
        id
        companyID
        clinicName
        address
        country
        state
        lat
        lng
        operationID
        serviceID
        bookings {
          nextToken
        }
        company {
          id
          companyName
          createdAt
          updatedAt
        }
        operation {
          id
          createdAt
          updatedAt
        }
        service {
          id
          createdAt
          updatedAt
        }
        lasers {
          nextToken
        }
        createdAt
        updatedAt
      }
      operation {
        id
        user {
          id
          companyID
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        deliveries {
          nextToken
        }
        clinics {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
