import React, {useState, useEffect} from 'react'

import Container from "react-bootstrap/Container";


import {API, graphqlOperation, Storage} from 'aws-amplify';
import {createLaser, createLaserType, updateLaserType} from '../../../graphql/mutations';
import {useParams} from "react-router-dom";

import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper';

import 'react-day-picker/lib/style.css';
import '../../../style/Lasers.css';

import NameInput from "../inputElements/NameInput";
import DescriptionInput from "../inputElements/DescriptionInput";
import ImageInput from '../inputElements/ImageInput'
import Submit from "../Submit"
import StepsHeader from "../StepsHeader";
import momentParser from "moment";


// TODO: custom hooks + con

let initCreateFormState = {
    name: '',
    description: '',
    imgFile: '',
    file: '',
    date: momentParser(new Date()).format('YYYY-MM-DD'),
    formStatus: 'createMode'

}


export default function LaserTypeControlled(props) {

    let {id, laserName, description, imgUrl} = useParams();

    let initFormState;

    if (laserName && description && imgUrl) {
        initFormState = {
            name: laserName,
            description: description,
            imgUrl: imgUrl,
            file: '',
            date: momentParser(new Date()).format('YYYY-MM-DD'),
            formStatus: 'updateMode'
        }

    } else {
        initFormState = initCreateFormState;
    }


    const [laserTypeForm, setLaserTypeForm] = useState(initFormState)
    const [stepper, setStepper] = useState({})

    const [file, setFile] = useState('')


    const structureForm = [

        {
            description:
                laserTypeForm.formStatus === 'createMode' ? "Creation Date" : "Update Date",
            value: laserTypeForm['date']
        },

        {description: "Laser Name", value: laserTypeForm['name']},
        {description: "Description", value: laserTypeForm['description']},
    ]


    useEffect(() => {
//ref
        setStepper(new Stepper(document.querySelector('#stepper1'),
            {
                linear: false,
                animation: true
            }))
    }, [])

    async function handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        const id = target.id;


        setLaserTypeForm({...laserTypeForm, [id]: value})
    }

    function handleInputImage(imgFile) {

        console.log(imgFile)

        setFile(imgFile)

    }


    async function createLaserTypeFun() {

        //TODO: add business logic to server

        setLaserTypeForm({...laserTypeForm, formStatus: 'isLoadingCreate'})

        let laserTypeDTO = {
            companyID: 'f4f21382-9179-4da0-9630-8919a203950d',
            laserName: laserTypeForm.name,
            description: laserTypeForm.description,
        }

        try {
            let result = await API.graphql(graphqlOperation(createLaserType, {input: laserTypeDTO}))
            let resultImg = await Storage.put(laserTypeDTO.companyID+'/'+result.data.createLaserType.id, file, {
                contentType: 'image/png' // contentType is optional
            });

            setLaserTypeForm({...laserTypeForm, formStatus: 'created'})
            console.log(resultImg)
        } catch (err) {
            console.log(err)
        }
    }

    async function updateLaserTypeFun() {

        //TODO: add business logic to server

        setLaserTypeForm({...laserTypeForm, formStatus: 'isLoadingUpdate'})

        let laserTypeDTO = {
            id: id,
            companyID: 'f4f21382-9179-4da0-9630-8919a203950d',
            laserName: laserTypeForm.name,
            description: laserTypeForm.description,
        }

        try {
            let result = await API.graphql(graphqlOperation(updateLaserType, {input: laserTypeDTO}))
            setLaserTypeForm({...laserTypeForm, formStatus: 'updated'})
            console.log(result)
        } catch (err) {
            console.log(err)
        }
    }

    function initForm() {

        setLaserTypeForm(initCreateFormState)
        setFile('')
        stepper.reset()
    }

//TODO: use context
    return (
        <Container>
            <div id="stepper1" className="bs-stepper">
                <div>
                    <StepsHeader steps={["Name", "Description", "Image"]}/>
                    <div className="bs-stepper-content">
                        <NameInput handleInputChange={handleInputChange}
                                   resource={'Laser Type'}
                                   name={laserTypeForm.name}
                                   stepper={stepper}/>

                        <DescriptionInput handleInputChange={handleInputChange}
                                          resource={'Laser Type'}
                                          description={laserTypeForm.description}
                                          stepper={stepper}
                        />
                        <ImageInput handleInputImage={handleInputImage}
                                    resource={'Laser Type'}
                                    file={laserTypeForm.file}
                                    stepper={stepper}
                        />
                        <Submit form={laserTypeForm}
                                stepper={stepper}
                                resource={{one: 'Type', plural: 'Lasers'}}
                                navOption={"/Lasers"}
                                createMode={true}
                                structureFrom={structureForm}
                                create={createLaserTypeFun}
                                update={updateLaserTypeFun}
                                initForm={initForm}/>
                    </div>
                </div>
            </div>
        </Container>
    );

}






