const initStatus = {valid: true, errorDescription: ''}

export default function formValidation(fromInput, inputType) {

    let status = initStatus;

            if(fromInput=== '' || fromInput === undefined){
                status= {valid: false, errorDescription: 'This field is required'}
            }
            else {
                switch (inputType){
                    case 'text':
                        status = checkText(fromInput)
                        break
                    case 'phone':
                        status = checkPhone(fromInput)
                        break
                    case 'email':
                        status = checkEmail(fromInput)
                        break
                    default:
                        status = checkText(fromInput)
                }
            }

    return status;
}

function checkText(textInput){

    if(textInput.length > 30){
        return {valid: false, errorDescription: 'The max length of the text is 30 characters'}
    }
    return initStatus
}

function checkEmail(emailInput){

    let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if(emailInput.match(mailformat)){

        return initStatus
    }
    return {status: false, description: 'Invalid email address'}

}

function checkPhone(phoneInput){


    //TODO: change this
    let phoneFormat = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;

    if(phoneInput.match(phoneFormat)){

        return initStatus
    }
    return {status: false, description: 'Invalid phone number'}

}



