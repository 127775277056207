import React, {useState, useEffect} from 'react'

import Container from "react-bootstrap/Container";

import '../style/Card.css'
import '../style/Lasers.css'
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

import * as Icon from "react-bootstrap-icons";
import {API, graphqlOperation, Auth, Storage} from 'aws-amplify';
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from './widgets/Modal'

import {Roller} from 'react-spinners-css';


import {listLasers, listLaserTypes} from '../graphql/queries';
import {deleteLaser, updateLaser} from '../graphql/mutations';
import {LinkContainer} from "react-router-bootstrap";

import Switch from "react-switch";

// TODO: use context

export default function Lasers(props) {

    const [companyLasers, setCompanyLasers] = useState([])

    useEffect(() => {

        async function fetchData() {

            try {

                //custom resolver
                let lasers = await API.graphql(graphqlOperation(listLasers))

                let arrLasers = lasers.data.listLasers.items

                let mapLasers = arrLasers.map((laser) => {

                    return {
                        id: laser.id,
                        serialNumber: laser.serialNumber,
                        laserTypeID: laser.typeID,
                        laserType: laser.type.laserName,
                        creationDate: laser.createdAt.substring(0, 10),
                        location: laser.currentClinic.clinicName,
                        status: laser.activeStatus,
                        activeStatus: laser.activeStatus,
                        currentBooking: laser.bookings.length
                    }
                })

                let data = await API.graphql({query: listLaserTypes})

                let types = data.data.listLaserTypes.items;

                let images = await Storage.list('f4f21382-9179-4da0-9630-8919a203950d/')
                // You can await here
                let arrImg = [];

                for (const img of images) {
                    let imgUrl = await Storage.get(img.key);
                    //let imgUrl = await Storage.get(img.key, {expires: 60});
                    const typeLaser = types.find(type => 'f4f21382-9179-4da0-9630-8919a203950d/' + type.id === img.key);

                    arrImg.push({key: img.key, laserName: typeLaser.laserName, imgUrl: imgUrl})
                }


                /*
                myBookings.sort((bookingA, bookingB) => {

                    let bookingADay = new Date(bookingA.startDate)

                    let bookingADayValue = momentParser([bookingADay.getFullYear(), bookingADay.getMonth(), bookingADay.getDate()]);
                    let bookingBDay = new Date(bookingB.startDate)
                    let bookingBDayValue = momentParser([bookingBDay.getFullYear(), bookingBDay.getMonth(), bookingBDay.getDate()]);

                    let diffDays = bookingADayValue.diff(bookingBDayValue, 'days')
                    return diffDays
                })

                 */

                mapLasers.map((laser) => {


                    let img = arrImg.find(img => img.laserName === laser.laserType)
                    laser.imgUrl = img.imgUrl;

                })
                setCompanyLasers(mapLasers)

            } catch (err) {

                console.log(err)
            }
        }

        fetchData();
    }, [])


    async function deleteSelectedLaser(laserID) {

        let laserDTO = {
            id: laserID
        }

        try {
            let result = await API.graphql(graphqlOperation(deleteLaser, {input: laserDTO}))
            setCompanyLasers(companyLasers.filter(laser => laser.id !== laserID))
            console.log(result)
        } catch (err) {
            console.log(err)
        }
    }

    function updateLaserStatus(laserDTO) {

        let updateArr;

        updateArr = companyLasers.map(laser => {

            if (laser.id === laserDTO.id) {
                laser.activeStatus = !laser.activeStatus
                return laser;
            } else {
                return laser
            }
        })

        setCompanyLasers(updateArr)


    }


    return (

        <Container>
            <Row className={'lasersTop'}>
                <Col md={6}>
                    <h3>Lasers Management</h3>
                </Col>

                <Col md={3}>
                    <LinkContainer to={"/LaserType"}>
                        <Button
                            className={'createLaserTypeBtn'}
                            variant={'outline-info'}
                            size="sm"
                            onClick={''}>
                            Create Laser Type<Icon.BagCheck size={15}/>
                        </Button>
                    </LinkContainer>
                </Col>
                <Col md={3}>

                    <LinkContainer to={"/Laser"}>
                        <Button
                            variant={'outline-success'}
                            size="sm"
                            onClick={''}>
                            Create Laser <Icon.BagCheck size={15}/>
                        </Button>
                    </LinkContainer>
                </Col>
            </Row>
            {companyLasers.length === 0 ?
                <Roller className={'spinner'} color="#4d94ff"/> :
                <>
                    <Container className={'bookingList'}>
                        <h5>Total company lasers: {companyLasers.length}, active
                            lasers: {companyLasers.filter(laser => laser.activeStatus === true).length}</h5>

                        {/* change to container listBooking and list lasers */}
                        <ListLasers lasers={companyLasers}
                                    deleteSelected={deleteSelectedLaser}
                                    updateLaserStatus={updateLaserStatus}/>
                    </Container>
                </>
            }
        </Container>

    )

}


function LaserStatus(props) {

    const [check, setCheck] = useState(props.laser.status)

    async function setLaserStatus() {

        setCheck(!check)

        let laserDTO = {
            id: props.laser.id,
            activeStatus: !check
        }

        try {
            let result = await API.graphql(graphqlOperation(updateLaser, {input: laserDTO}))
            console.log(result)
            props.updateLaserStatus(laserDTO)
        } catch (err) {
            console.log(err)
        }
    }


    return (

        <div className={'laserStatus'}>

            <label>
                <Switch
                    checked={check}
                    onChange={setLaserStatus}
                    offColor="#e6e6e6"
                    onColor="#28a745"
                    onHandleColor="#ffffff"
                    handleDiameter={17}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={14}
                    width={42}
                    className="react-switch"
                    id="material-switch"
                />
            </label>
            <label className={'statusLabel'}>
                {check ? 'Active' : 'Inactive'}
            </label>
        </div>
    )
}

//TODO: refactor


function ListLasers(props) {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedLaser, setSelectedLaser] = useState(0);


    async function deleteSelected() {

        await props.deleteSelected(selectedLaser)
        setShowDeleteModal(!showDeleteModal)

    }

    return (
        props.lasers.map((laser) =>
                <Card className={'item'} border='light'>
                    <Row className={'cardWrapper'}>
                                <span className={'laserImg'}>
                                    <Image src={laser.imgUrl}/>
                                </span>
                        <span key={laser.toString()} className={'cardInfo'} border="light">
                                    <Row>
                                        <Col md={4}>
                                        <h5 className={'laserType'}>{laser.laserType}</h5>
                                        <div>Serial Number:<span
                                            className={'itemData'}>{laser.serialNumber}</span>
                                        </div>
                                        <div>Current Location:<span className={'itemData'}>
                                              {laser.location}</span>
                                        </div>
                                        <div>Creation Date:<span
                                            className={'itemData'}>{laser.creationDate}</span>
                                        </div>
                                            {/*
                                            <div>Current Bookings:<span
                                            className={'itemData'}>{laser.currentBooking}</span>
                                        </div>
                                            */}

                                        </Col>
                                        <Col md={{span: 7, offset: 1}}>
                                            <Row>
                                                <LaserStatus laser={laser}
                                                             updateLaserStatus={props.updateLaserStatus}/>
                                            </Row>
                                        </Col>
                                        </Row>
                                        <Row className={'lasersBtns'}>
                                             <Col md={{span: 3, offset: 2}} className={'mLeft'}>
                                          <Button variant={"outline-danger"} size="sm"
                                                  onClick={() => {
                                                      setSelectedLaser(laser.id)
                                                      setShowDeleteModal(!showDeleteModal)
                                                  }}>
                                                        Delete <Icon.DashCircle size={15}/>
                                                    </Button>
                                                    <Modal showModal={showDeleteModal}
                                                           setShow={setShowDeleteModal}
                                                           deleteSelected={deleteSelected}
                                                           title={'Delete Laser'}
                                                           body={'Are you sure you want to delete the Laser?'}
                                                           btnColor={'outline-danger'}
                                                           btnText={'Delete'}
                                                    />
                                           </Col>
                                            <Col md={3}>
                                                    <LinkContainer
                                                        to={"/Laser/" + laser.id + '/' + laser.serialNumber + '/' + laser.laserType + '/' + laser.location + '/' + laser.laserTypeID}>
                                                        <Button variant={"outline-warning"}
                                                                size="sm">Update
                                                            <Icon.ArrowRepeat size={15}/>
                                                        </Button>
                                                    </LinkContainer>
                                            </Col>
                                            <Col md={3}>
                                                    <LinkContainer
                                                            to={"/Malfunction/" + laser.id + '/' + laser.serialNumber + '/' + laser.laserType + '/' + laser.location + '/' + laser.laserTypeID}>
                                                        <Button variant={"outline-info"}
                                                                size="sm">Service
                                                            <Icon.Gear size={15}/>
                                                        </Button>
                                                    </LinkContainer>


                                             </Col>
                                        </Row>

                                </span>

                    </Row>

                </Card>
        ))
}

