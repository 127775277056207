import React from "react";
import * as Icon from "react-bootstrap-icons";


export default function SerialNumberInput(props) {

    return (
        <div id="serialNumber" className="content">
            <div className="form-group">
                <div className={'stageInput'}>
                    <label htmlFor="serialNumber">Serial Number</label>
                    {props.selectOptions?
                        <select className={'form-control'} id="serialNumber"
                                onChange={props.handleInputChange}
                                value={props.selectedSerialNumber}>
                            <option value="001">001</option>
                            <option value="002">002</option>
                            <option value="003">003</option>
                            <option value="004">004</option>
                        </select>
                        :
                        <input className={'form-control'} id="serialNumber"
                               onChange={props.handleInputChange}
                               value={props.selectedSerialNumber}>
                        </input>

                    }

                </div>
                <div className={'stepperBtnClinic'}>
                    <button className="btn btn-outline-secondary"
                            onClick={() => props.stepper.previous()}>
                        <Icon.CaretLeft size={17}/> Previous
                    </button>
                    <button className="btn btn-outline-primary"
                            onClick={() => props.stepper.next()}>
                        Next <Icon.CaretRight size={15}/>
                    </button>
                </div>
            </div>
        </div>
    )
}