import React, {useState} from "react";
import * as Icon from "react-bootstrap-icons";
import Map from "../../widgets/Map";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function LocationInput(props) {

    const [address, setAddress] = useState(props.address)
    const [clinics, setClinics] = useState([])

    const [zoom, setZoom] = useState(7)


    /*
        function geocodeAddress() {

            const geocoder = new window.google.maps.Geocoder();

            geocoder.geocode({address: address}, (results, status) => {
                if (status === "OK") {
                    resultsMap.setCenter(results[0].geometry.location);
                    new google.maps.Marker({
                        map: resultsMap,
                        position: results[0].geometry.location,
                    });
                } else {
                    alert("Geocode was not successful for the following reason: " + status);
                }
            });
        }

     */
    function searchAddress() {

        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({address: address}, (results, status) => {
            if (status === "OK") {

                let clinicsArr = []
                let locationData = results[0].geometry.viewport
                let lat = (locationData.Ua.i + locationData.Ua.g) / 2;
                let lng = (locationData.La.i + locationData.La.g) / 2;
                clinicsArr[0] = {lat: lat, lng: lng}
                setZoom(15);
                setClinics(clinicsArr);

                props.handleInputLocation(address, lat, lng)

            } else {
                alert("Geocode was not successful for the following reason: " + status);
            }
        });

    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        setAddress(value)
    }

    return (
        <div id="location" className="content">

            <div className="form-group">
                <Row className={'clinicAddressInput'}>
                    <Col md={6}>
                        <div>
                            <label htmlFor="location">{props.resource} Address</label>
                            <input className={'form-control'} id="address"
                                   onChange={handleInputChange}
                                   value={address}>

                            </input>
                        </div>
                    </Col>

                    <Col md={6} className={'searchBtn'}>
                        <button className="btn btn-outline-info"
                                onClick={searchAddress}
                        >Search Location
                        </button>
                    </Col>
                </Row>
                <Map clinics={clinics} styleMap={'map'} zoom={zoom}/>
                <div className={'stepperBtnClinic'}>
                    <button className="btn btn-outline-secondary"
                            onClick={() => props.stepper.previous()}>
                        <Icon.CaretLeft size={17}/> Previous
                    </button>
                    <button className="btn btn-outline-primary"
                            onClick={() => props.stepper.next()}>
                        Next <Icon.CaretRight size={15}/>
                    </button>
                </div>
            </div>
        </div>
    )
}