import React, {useEffect, useState} from 'react'

import Container from "react-bootstrap/Container";
import {API, graphqlOperation, Storage} from 'aws-amplify';
import {
    createBooking,
    createDelivery,
    updateBooking,
    updateDelivery
} from '../graphql/mutations';
import {
    deliveryByBookingID,
    getMedicalClinic,
    listLasers,
    listLaserTypes,
    listMedicalClinics
} from '../graphql/queries';
import {useParams} from "react-router-dom";


import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper';
import 'react-day-picker/lib/style.css';
import '../style/ManagmentPanel.css';
import '../style/Bookings.css';
import StepsHeader from "./forms/StepsHeader";
import LaserInput from "./forms/inputElements/LaserTypeInput";
import ClinicInput from "./forms/inputElements/ClinicInput";
import DateRangeInput from "./forms/inputElements/DateRangeInput";
import Submit from "./forms/Submit";

import momentParser from "moment";
import {onCreateBooking, onUpdateBooking} from "../graphql/subscriptions";

// TODO: custom hooks + con

let initCreateFormState = {
    laserType: 'CO2',
    bookingStartDate: null,
    bookingEndDate: null,
    medicalClinic: {lat: 30.1933, lng: -85.6588},
    date: momentParser(new Date()).format('YYYY-MM-DD'),
    formStatus: 'createMode'
}
const initAppData = {
    arrImgUrl: [],
    clinics: [],
    types: [],
    lasers: []
}

const initCreateDatePicker = {

    from: null,
    to: null,
    enteredTo: null,
}

export default function Bookings(props) {

    //TODO: use custom hook form validation anf error + use context and reducer

    let {id, laserType, startDate, endDate, clinic} = useParams();


    // use reducer to state management and use context user data

    let initFormState;
    let initDatePicker;

    if (laserType && startDate && endDate && clinic) {
        initFormState = {
            laserType: laserType,
            bookingStartDate: startDate,
            bookingEndDate: endDate,
            medicalClinic: {lat: 30.1933, lng: -85.6588},
            formStatus: 'updateMode',
            date: momentParser(new Date()).format('YYYY-MM-DD')
        }

        initDatePicker = {

            from: new Date(startDate),
            to: new Date(endDate),
            enteredTo: new Date(endDate),
        }
    } else {
        initFormState = initCreateFormState;
        initDatePicker = initCreateDatePicker
    }

    const [bookingForm, setBookingForm] = useState(initFormState)
    const [appData, setAppData] = useState(initAppData)

    const [selectedImgUrl, setSelectedImgUrl] = useState('')
    const [stepper, setStepper] = useState({})
    const [datePickerState, setDatePickerState] = useState(initDatePicker)

    const structureForm = [

        {
            description:
                bookingForm.formStatus === 'createMode' || 'isLoadingCreate' || 'created' ? "Order Date" : "Update Date",
            value: bookingForm['date']
        },

        {description: "Laser Type", value: bookingForm['laserType']},
        {description: "Start Date", value: bookingForm['bookingStartDate']},
        {description: "End Date", value: bookingForm['bookingEndDate']},
        {description: "Clinic", value: bookingForm['medicalClinic'].clinicName},
    ]


    useEffect(() => {
//ref
        setStepper(new Stepper(document.querySelector('#stepper1'),
            {
                linear: false,
                animation: true
            }))

        async function initData() {

            let lasers = await initLasers()
            let types = await initTypes(lasers);
            let clinics = await initClinics();
            let arrImgUrl = await initImages(types)
            setAppData({...appData, clinics: clinics, arrImgUrl: arrImgUrl, types: types, lasers: lasers});
        }

        initData();
    }, [])

    async function initLasers() {


        try {

            let data = await API.graphql({query: listLasers})

            let lasers = data.data.listLasers.items;

            return lasers;

        } catch (err) {

            console.log(err)
        }
    }

    async function initTypes(lasers) {


        try {

            let data = await API.graphql({query: listLaserTypes})

            let types = data.data.listLaserTypes.items;

            const filterTypes = types.filter(type => lasers.find(laser => laser.typeID === type.id));


            return filterTypes;

        } catch (err) {

            console.log(err)
        }
    }

    async function initImages(types) {

        let arrImgUrl = [];

        try {

            const images = await Storage.list('f4f21382-9179-4da0-9630-8919a203950d/')

            for (const img of images) {
                let imgUrl = await Storage.get(img.key);
                //let imgUrl = await Storage.get(img.key, {expires: 60});

                const typeLaser = types.find(type => 'f4f21382-9179-4da0-9630-8919a203950d/' + type.id === img.key);

                if (typeLaser) {
                    arrImgUrl.push({
                        kek: img.key,
                        imgUrl: imgUrl,
                        laserName: typeLaser.laserName,
                        description: typeLaser.description
                    })

                }
            }
            if (bookingForm.formStatus === 'updateMode') {

                setSelectedImgUrl(arrImgUrl.find(laserIndex => laserIndex.laserName === bookingForm.laserType).imgUrl);
            } else {
                setSelectedImgUrl(arrImgUrl[0].imgUrl);
            }
            return arrImgUrl

        } catch (err) {

            console.log(err)
        }
    }

    async function initClinics() {

        try {
            let data = await API.graphql({query: listMedicalClinics})

            let clinics = data.data.listMedicalClinics.items;
            if (bookingForm.formStatus === 'updateMode') {
                let updateClinic = clinics.find(medicalClinic => medicalClinic.clinicName === clinic)
                setBookingForm({...bookingForm, medicalClinic: updateClinic})

            } else {
                setBookingForm({...bookingForm, medicalClinic: clinics[0]})
            }
            return clinics;

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {


        if (datePickerState.from !== null) {

            let from = momentParser(datePickerState.from).format('YYYY-MM-DD')

            setBookingForm({...bookingForm, bookingStartDate: from})
        }
        if (datePickerState.to !== null) {

            let to = momentParser(datePickerState.to).format('YYYY-MM-DD')

            setBookingForm({...bookingForm, bookingEndDate: to})
        }

    }, [datePickerState])


    async function handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        const id = target.id;


        if (id === 'laserType') {
            setSelectedImgUrl(appData.arrImgUrl.find(laserIndex => laserIndex.laserName === value).imgUrl)
        }
        if (id === 'medicalClinic') {
            value = appData.clinics.find(clinic => clinic.clinicName === value)
        }
        setBookingForm({...bookingForm, [id]: value})
    }


    function getLaserIDByType(laserType) {

        let laser = appData.lasers.find(laser => laser.type.laserName === laserType)


        return laser.id;

    }

    /*
        async function fetchLasersByType(laserType) {

            let lasersData = await API.graphql({query: lasersByType, variables: {laserType: laserType}});

            let bookingData = lasersData.data.lasersByType.items

            this.setDisableBookingOnCalendar(bookingData)

        }

     */


    function setDisableBookingOnCalendar(bookingData) {

        //TODO: refactor

        bookingData.forEach((laser) => {

            let arrayOfRange = [];

            if (laser.bookings.items.length > 0) {

                laser.bookings.items.forEach((booking) => {

                    let a = new Date(booking.startDate)

                    arrayOfRange.push({
                        after: new Date(2021, 0, 10),
                        before: new Date(2021, 0, 20),
                    })
                })
            }
        })


    }

    function calcDrivingDistance(directionsService, originLat, originLng, destLat, destLng) {

        //TODO: add business logic to server or custom hook

        const route = {
            origin: new window.google.maps.LatLng(originLat, originLng),
            destination: new window.google.maps.LatLng(destLat, destLng),
            travelMode: window.google.maps.TravelMode.DRIVING,
            drivingOptions: {
                departureTime: new Date(Date.now()),  // for the time N milliseconds from now.
                trafficModel: 'optimistic'
            }
        }

        return new Promise((res, rej) => {

            directionsService.route(route,
                (response, status) => { // anonymous function to capture directions
                    if (status !== 'OK') {
                        rej(status);
                    } else {
                        let directionsData = response.routes[0].legs[0]; // Get data about the mapped route
                        if (!directionsData) {
                            rej('Direction nt success')
                        } else {
                            let durationText = directionsData.distance.text;
                            let result;
                            if (durationText.length === 6) {
                                result = durationText.substring(0, 3)
                            } else {
                                result = durationText.substring(0, 4)
                            }
                            res({distance: Number(result), drivingTime: directionsData.duration.text})
                        }
                    }
                });
        })
    }


    async function createBookingForLaser() {

        //TODO: add business logic to server

        setBookingForm({...bookingForm, formStatus: 'isLoadingCreate'})

        let serialNumber = getLaserIDByType(bookingForm.laserType)

        let bookingDTO = {
            customerID: "f8cfd744-4892-4f41-9e29-16fa938c45b7",
            startDate: bookingForm.bookingStartDate,
            endDate: bookingForm.bookingEndDate,
            medicalClinicID: bookingForm.medicalClinic.id,
            laserID: serialNumber,
            //groups: ["CompanyAdmins"]
        }

        try {

            let clinic = await API.graphql(graphqlOperation(getMedicalClinic, {id: bookingDTO.medicalClinicID}))
            let resultBooking = await API.graphql(graphqlOperation(createBooking, {input: bookingDTO}))

            let directionsService = new window.google.maps.DirectionsService();

            let clinics = appData.clinics.filter(clinicIndex => clinicIndex.id !== bookingForm.medicalClinic.id)

            let nearestClinic = null;

            for (let clinic of clinics) {

                let routeData = await calcDrivingDistance(
                    directionsService,
                    clinic.lat,
                    clinic.lng,
                    bookingForm.medicalClinic.lat,
                    bookingForm.medicalClinic.lng
                )

                if (nearestClinic === null) {
                    nearestClinic = {
                        clinic: clinic,
                        distance: routeData.distance,
                        drivingTime: routeData.drivingTime
                    }
                } else {
                    if (routeData.distance < nearestClinic.distance)
                        nearestClinic = {
                            clinic: clinic,
                            distance: routeData.distance,
                            drivingTime: routeData.drivingTime
                        }
                }
            }


            let deliveryDTO = {
                bookingID: resultBooking.data.createBooking.id,
                operationID: clinic.data.getMedicalClinic.operation.id,
                drivingTime: nearestClinic.drivingTime,
                fromClinicID: nearestClinic.clinic.id,
            }

            let resultDelivery = await API.graphql(graphqlOperation(createDelivery, {input: deliveryDTO}))

            setBookingForm({...bookingForm, formStatus: 'created'})
            console.log(resultDelivery)
        } catch (err) {
            console.log(err)
        }
    }


    const subscription = API.graphql(
        graphqlOperation(onCreateBooking)
    ).subscribe({
        next: function ({ provider, value }){
            console.log({ provider, value })
        },
        error: function(error) {

            console.warn(error)

        }
    });

    const subscription1 = API.graphql(
        graphqlOperation(onUpdateBooking)
    ).subscribe({
        next: function ({ provider, value }){
            console.log({ provider, value })
        },
        error: function(error) {

            console.warn(error)
        }
    });

    async function updateBookingForLaser() {

        //TODO: add business logic to server

        setBookingForm({...bookingForm, formStatus: 'isLoadingUpdate'})

        let serialNumber = getLaserIDByType(bookingForm.laserType)

        let bookingDTO = {
            id: id,
            customerID: "f8cfd744-4892-4f41-9e29-16fa938c45b7",
            startDate: bookingForm.bookingStartDate,
            endDate: bookingForm.bookingEndDate,
            medicalClinicID: bookingForm.medicalClinic.id,
            laserID: serialNumber,
            groups: ["CompanyAdmins"]
        }
        try {
            let deliveryData = await API.graphql({query: deliveryByBookingID, variables: {bookingID: id}})

            const deliveryID = deliveryData.data.listDeliverys.items[0].id


            let clinic = await API.graphql(graphqlOperation(getMedicalClinic, {id: bookingDTO.medicalClinicID}))

            let directionsService = new window.google.maps.DirectionsService();

            let clinics = appData.clinics.filter(clinicIndex => clinicIndex.id !== bookingForm.medicalClinic.id)

            let nearestClinic = null;

            for (let clinic of clinics) {

                let routeData = await calcDrivingDistance(
                    directionsService,
                    clinic.lat,
                    clinic.lng,
                    bookingForm.medicalClinic.lat,
                    bookingForm.medicalClinic.lng
                )

                if (nearestClinic === null) {
                    nearestClinic = {
                        clinic: clinic,
                        distance: routeData.distance,
                        drivingTime: routeData.drivingTime
                    }
                } else {
                    if (routeData.distance < nearestClinic.distance)
                        nearestClinic = {
                            clinic: clinic,
                            distance: routeData.distance,
                            drivingTime: routeData.drivingTime
                        }
                }
            }

            let deliveryDTO = {
                id: deliveryID,
                operationID: clinic.data.getMedicalClinic.operation.id,
                drivingTime: nearestClinic.drivingTime,
                fromClinicID: nearestClinic.clinic.id,
            }
            let resultBooking = await API.graphql(graphqlOperation(updateBooking, {input: bookingDTO}))

            let resultDelivery = await API.graphql(graphqlOperation(updateDelivery, {input: deliveryDTO}))

            setBookingForm({...bookingForm, formStatus: 'updated'})
            console.log(resultDelivery)
        } catch (err) {
            console.log(err)
        }
    }

    function initForm() {

        setBookingForm({
            laserType: 'CO2',
            bookingStartDate: null,
            bookingEndDate: null,
            medicalClinic: appData.clinics[0],
            date: momentParser(new Date()).format('YYYY-MM-DD'),
            formStatus: 'createMode'
        })
        setSelectedImgUrl(appData.arrImgUrl[0].imgUrl);
        setDatePickerState(initCreateDatePicker)
        stepper.reset()
    }


    return (
        <Container>
            <div id="stepper1" className="bs-stepper">
                <div>
                    <StepsHeader steps={["LaserType", "DateRange", "Clinic"]}/>
                    <div className="bs-stepper-content">
                        <LaserInput handleInputChange={handleInputChange}
                                    selectedImgUrl={selectedImgUrl}
                                    selectedLaserType={bookingForm.laserType}
                                    types={appData.types}
                                    stepper={stepper}/>
                        <ClinicInput handleInputChange={handleInputChange}
                                     clinics={appData.clinics}
                                     selectedMedicalClinic={bookingForm.medicalClinic}
                                     stepper={stepper}
                                     route={false}/>
                        <DateRangeInput datePickerState={datePickerState}
                                        setDatePickerState={setDatePickerState}
                                        bookingFormStatus={bookingForm.formStatus}
                                        stepper={stepper}/>
                        <Submit form={bookingForm}
                                stepper={stepper}
                                resource={{one: 'Booking', plural: 'Bookings'}}
                                navOption={"/Activity"}
                                createMode={true}
                                structureFrom={structureForm}
                                create={createBookingForLaser}
                                update={updateBookingForLaser}
                                initForm={initForm}/>
                    </div>
                </div>
            </div>
        </Container>
    );
}


//TODO: one handle submit
