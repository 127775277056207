//export const initialAuthState = {status: 'signIn', loading: false, error: ''};

export function authReducer(authState, action) {

    let loading = action?.payload?.loading;
    let error = action?.payload?.error;
    let user = action?.payload?.user;

    switch (action.type) {
        case "signIn":
            return { ...authState, status:'signIn', loading: loading, error: error }
        case "signedIn":
            return { ...authState, status:'signedIn', loading: loading, error: error, user: user}
        case "signOut":
            return { ...authState, status:'signOut', loading: loading, error: error, user: null}
        case "confirmSignUp":
            return { ...authState, status:'confirmSignUp', loading: loading, error: error }
        case "signUp":
            return { ...authState, status:'signUp', loading: loading, error: error }
        case "resetPassword":
            return { ...authState, status:'resetPassword', loading: loading, error: error }
        case "newPassword":
            return { ...authState, status:'newPassword', loading: loading, error: error }
        default:
            return { ...authState, status:'signIn', loading: false, error: '' }
    }
}
