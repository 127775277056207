import React, {useState, useEffect} from "react";
import DayPicker, {DateUtils} from "react-day-picker";
import Button from "react-bootstrap/Button";
import * as Icon from "react-bootstrap-icons";


//selectedDays: [initDatePicker.from, {from: initDatePicker.from, to: initDatePicker.enteredTo}],


//disabledDays: {days: []},
/*
myModifiers: {

    days: [new Date(2020, 12, 25),
        new Date(2020, 12, 26),
        new Date(2020, 12, 5),
        new Date(2020, 12, 6),
    ],
}

 */


export default function DateRangeInput(props) {

    const initModifiers = {start: props.datePickerState.from, end: props.datePickerState.enteredTo}

    const initSelectedDays = [{from: props.datePickerState.from}, {
        from: props.datePickerState.from,
        to: props.datePickerState.to
    }]

    const [calendarModifiers, setCalendarModifiers] = useState(initModifiers)
    const [selectedDays, setSelectedDays] = useState(initSelectedDays)

    useEffect(()=>{
        setCalendarModifiers(initModifiers)
        setSelectedDays(initSelectedDays)
    }, [props.bookingFormStatus])

    function isSelectingFirstDay(from, to, day) {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }

    function handleDayClick(day) {
        const {from, to} = props.datePickerState;
        if (from && to && day >= from && day <= to) {
            handleResetClick();
            return;
        }
        if (isSelectingFirstDay(from, to, day)) {

            props.setDatePickerState({...props.datePickerState, from: day, to: null, enteredTo: null})
            setSelectedDays([{from: day}, {from: day, to: null}])

            setCalendarModifiers({start: props.datePickerState.from, end: props.datePickerState.enteredTo}
            )
        } else {
            props.setDatePickerState({...props.datePickerState, to: day, enteredTo: day});

            setCalendarModifiers({start: props.datePickerState.from, end: props.datePickerState.enteredTo}
            )
            setSelectedDays([{from: props.datePickerState.from}, {from: props.datePickerState.from, to: day}])
        }
    }

    function handleDayMouseEnter(day) {
        const {from, to} = props.datePickerState;
        if (!isSelectingFirstDay(from, to, day)) {
            props.setDatePickerState({...props.datePickerState, enteredTo: day});
            setCalendarModifiers({start: props.datePickerState.from, end: day})
            setSelectedDays([{from: props.datePickerState.from}, {from: props.datePickerState.from, to: day}])
        }
    }

    function handleResetClick() {

        props.setDatePickerState({...props.datePickerState, from: null, to: null, enteredTo: null})
        setCalendarModifiers({start: props.datePickerState.from, end: props.datePickerState.enteredTo})
        setSelectedDays([{from: null}, {from: null, to: null}])
    }


    return (
        <div id="rangeDate" className="content text-center">
            <div className={'schedulerRange'}>
                <DayPicker
                    className="Range"
                    numberOfMonths={2}
                    fromMonth={props.datePickerState.from}
                    selectedDays={selectedDays}
                    //disabledDays={calendarModifiers.disabledDays.days}
                    modifiers={calendarModifiers}
                    onDayClick={handleDayClick}
                    onDayMouseEnter={handleDayMouseEnter}
                />
                <div>
                    {!props.datePickerState.from && !props.datePickerState.to && 'Please select the first order day.'}
                    {props.datePickerState.from && !props.datePickerState.to && 'Please select the last order day.'}
                    {props.datePickerState.from &&
                    props.datePickerState.to &&
                    `Selected from ${props.datePickerState.from.toLocaleDateString()} to
                                            ${props.datePickerState.to.toLocaleDateString()}`}{' '}
                    {props.datePickerState.from && props.datePickerState.to && (
                        <div>
                            <Button variant="outline-info" size="sm"
                                    onClick={handleResetClick}>
                                Reset
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div className={'stepperBtn'}>
                <button className="btn btn-outline-secondary"
                        onClick={() => props.stepper.previous()}>
                    <Icon.CaretLeft size={17}/> Previous
                </button>
                <button className="btn btn-outline-primary"
                        onClick={() => props.stepper.next()}>
                    Next <Icon.CaretRight size={15}/>
                </button>
            </div>
        </div>
    )
}