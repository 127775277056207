import React from "react";
import * as Icon from "react-bootstrap-icons";


export default function DescriptionInput(props) {

    return (
        <div id="description" className="content">
            <div className="form-group">
                <div className={'stageInput'}>
                    <label htmlFor="description">{props.resource} Description</label>

                    <textarea className={'form-control'} id="description"
                              onChange={props.handleInputChange}
                              value={props.description} rows="4" cols="70">
                    </textarea>
                </div>
                <div className={'stepperBtnFirst'}>
                    <button className="btn btn-outline-primary"
                            onClick={() => props.stepper.next()}>
                        Next <Icon.CaretRight size={15}/>
                    </button>
                </div>
            </div>
        </div>
    )
}