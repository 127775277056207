import React, {useState, useEffect} from "react";

import Amplify, {API,graphqlOperation, Auth, DataStore} from 'aws-amplify';
import awsconfig from './aws-exports';
import AppRouter from "./navigation/Router";
import Chat from "./components/widgets/Chat";
import '../src/App.css';
import Login from './pages/login';
import AppProvider from "./contexts/appProvider";
import {onCreateBooking, onCreateTodo, onUpdateTodo, onUpdateCompany, onCreateCompany} from './graphql/subscriptions';
import {getTodos, listLasers} from "./graphql/queries";
import { Company} from "./models";
import { createTodo, createUser} from "./graphql/mutations";
Amplify.configure(awsconfig);



//console.log(process.env.NODE_ENV)

// TODO: use dynamic import or react lazy and context
//TODO: use memo to improve performance

export default function App() {

// override default resolver




    return (

        <AppProvider>
            <AppRouter/>
            <Chat/>
        </AppProvider>
    )

}




//@auth(rules: [{allow: public}])

/*

import {Hub, Logger} from 'aws-amplify';

const logger = new Logger('My-Logger');

const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
            logger.info('user signed in');
            break;
        case 'signUp':
            logger.info('user signed up');
            break;
        case 'signOut':
            logger.info('user signed out');
            break;
        case 'signIn_failure':
            logger.error('user sign in failed');
            break;
        case 'tokenRefresh':
            logger.info('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            logger.error('token refresh failed');
            break;
        case 'configured':
            logger.info('the Auth module is configured');
    }
}

Hub.listen('auth', listener);


 */
// TODO: remove src directory from production only buil
