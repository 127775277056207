import React, {lazy, Suspense, useContext, useEffect} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    Redirect
} from "react-router-dom";

import Login from '../pages/login'
import PrivateRoute from "./PrivateRoute";

import AppNavbar from "./Navbar";

import Home from '../components/Home'

import Bookings from '../components/Bookings'
import Operations from "../components/Operations";
import Invoices from "../components/Invoices";
import Statistics from "../components/Statistics";
import Lasers from "../components/Lasers";
import LaserControlled from "../components/forms/controlled/LaserControlled";
import ClinicControlled from "../components/forms/controlled/ClinicControlled";
import LaserTypeControlled from "../components/forms/controlled/LaserTypeControlled";
import MalfunctionControlled from "../components/forms/controlled/MalfunctionControlled";
import DeliveryForm from "../components/forms/controlled/DeliveryControlled";
import Activity from "../components/Activity";
import Accounting from "../components/Accounting";
import Users from "../components/Users";
import Clinics from "../components/Clinics"
import Service from "../components/Service";
import {AuthContext} from "../contexts/authContext";
import './navigation.css'
/*
const Bookings = lazy(() => import('../Bookings'));
const Activity = lazy(() => import('../Activity'));
const Lasers = lazy(() => import('../Lasers'));
const LaserControlled = lazy(() => import('../forms/controlled/LaserControlled'));
const LaserTypeControlled = lazy(() => import('../forms/controlled/LaserTypeControlled'));
const Service = lazy(() => import('../Service'));
const Operations = lazy(() => import('../Operations'));
const Clinics = lazy(() => import('../Clinics'));
const ClinicControlled = lazy(() => import('../forms/controlled/ClinicControlled'));
const Invoices = lazy(() => import('../Invoices'));
const Statistics = lazy(() => import('../Statistics'));
const DeliveryForm = lazy(() => import('../forms/controlled/DeliveryControlled'));
const MalfunctionControlled = lazy(() => import('../forms/controlled/MalfunctionControlled'));


 */

// TODO: use dynamic import or react lazy and context


// Todo: use switch and no match


export default function AppRouter (){

    let {authState} = useContext(AuthContext);

    return (
        <Router>
            {
                authState.status === 'signedIn' ?
                    <AppNavbar/> :
                    <></>
            }
            <Suspense fallback={<></>}>
                <>
                    <Route path="/Login">
                        {
                            authState.status === 'signedIn' ?
                            <Redirect to="/Home" /> :
                            <Login/>
                        }
                    </Route>
                    <PrivateRoute exact path="/">
                        <Home/>
                    </PrivateRoute>
                    <PrivateRoute exact path="/Home">
                        <Home/>
                    </PrivateRoute>
                    <PrivateRoute path="/Booking/:id?/:laserType?/:startDate?/:endDate?/:clinic?">
                        <Bookings/>
                    </PrivateRoute>
                    <PrivateRoute path="/Statistics">
                        <Statistics/>
                    </PrivateRoute>
                    <PrivateRoute path="/Accounting">
                        <Accounting/>
                    </PrivateRoute>
                    <PrivateRoute path="/Activity">
                        <Activity/>
                    </PrivateRoute>
                    <PrivateRoute path="/Operations">
                        <Operations/>
                    </PrivateRoute>
                    <PrivateRoute
                        path="/Delivery/:id?/:laserType?/:deliveryDate?/:serialNumber?/:toClinic?/:fromClinic?/:drivingTime?/:bookingID?">
                        <DeliveryForm/>
                    </PrivateRoute>
                    <PrivateRoute path="/Lasers">
                        <Lasers/>
                    </PrivateRoute>
                    <PrivateRoute path="/LaserType/:id?/:laserName?/:description?/:imgUrl?">
                        <LaserTypeControlled/>
                    </PrivateRoute>
                    <PrivateRoute path="/Malfunction/:id?/:serialNumber?/:laserType?/:clinic?/:typeID?">
                        <MalfunctionControlled/>
                    </PrivateRoute>
                    <PrivateRoute path="/Laser/:id?/:serialNumber?/:laserType?/:clinic?/:typeID?">
                        <LaserControlled/>
                    </PrivateRoute>
                    <PrivateRoute path="/Users">
                        <Users/>
                    </PrivateRoute>
                    <PrivateRoute path="Users">
                        <Users/>
                    </PrivateRoute>
                    <PrivateRoute path="/Clinics">
                        <Clinics/>
                    </PrivateRoute>
                    <PrivateRoute path="/Clinic">
                        <ClinicControlled/>
                    </PrivateRoute>
                    <PrivateRoute path="/Invoices">
                        <Invoices/>
                    </PrivateRoute>
                    <PrivateRoute path="/Service">
                        <Service/>
                    </PrivateRoute>

                </>
            </Suspense>
        </Router>
    );
}
