import React, {useState, useEffect} from "react";

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import '../style/ManagmentPanel.css';


import SubmitInvoice from "./forms/SubmitInvoice";
import {useParams, useLocation} from "react-router-dom";
import '../style/Accounting.css'
import {API, graphqlOperation} from "aws-amplify";
import {listLasers} from "../graphql/queries";
import {createInvoice, createLaser} from '../graphql/mutations';


import momentParser from 'moment';


export default function Accounting(props) {

    let {auth} = useParams();

    const [customers, setCustomers] = useState([])

    const [invoiceForm, setInvoiceForm] = useState({
        customer: {},
        invoiceDate: new Date(),
        activeLasers: [],
        formStatus: 'createMode'
    })

    function handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        const id = target.id;

        if (id === 'customer') {

            let selectedCustomer = customers.find(customer => customer.DisplayName === value)

            value = selectedCustomer
        }
        //IncomeAccountRef

        setInvoiceForm({...invoiceForm, [id]: value})

    }

    useEffect(() => {

        if (auth) {
            getAccountingData()
        } else {
            handleQBConn()
        }


    }, [])

    async function handleQBConn() {

        try {

            let result = await fetch('http://localhost:3000/users/token');

            let data = await result.json()

            //TODO: change

            window.location = data.data

            //location = data.data;

        } catch (err) {

            console.log(err)
        }
    }

    async function getAccountingData() {

        try {

            /*

            let accountsResult = await fetch('http://localhost:3000/accounts/');

            let accountsData = await accountsResult.json()

            let invoicesResult = await fetch('http://localhost:3000/invoices/');

            let invoicesData = await invoicesResult.json()

            let itemsResult = await fetch('http://localhost:3000/items/');

            let itemsData = await itemsResult.json()
             */

            let customersResult = await fetch('http://localhost:3000/customers/');


            let customersData = await customersResult.json()

            let arrCustomers = customersData.data;

            const customIndex = arrCustomers.findIndex(customer => customer.DisplayName === 'VenusConcept')

            let customer = arrCustomers[customIndex]

            arrCustomers[customIndex] = arrCustomers[0]

            arrCustomers[0] = customer

            let lasers = await API.graphql(graphqlOperation(listLasers))

            let arrLasers = lasers.data.listLasers.items

            let activeLasers = arrLasers.filter(laser => laser.activeStatus === true)

            setCustomers(arrCustomers)
            setInvoiceForm({...invoiceForm, customer: customersData.data[0], activeLasers: activeLasers.length})

        } catch (err) {

            console.log(err)
        }

    }

    async function createInvoiceQB() {

        setInvoiceForm({...invoiceForm, formStatus: 'isLoadingCreate'})


        let data = {
            "Line": [
                {
                    "DetailType": "SalesItemLineDetail",
                    "Amount": invoiceForm.activeLasers * 1500,
                    "SalesItemLineDetail": {
                        "ItemRef": {
                            "name": "Laser Services",
                            "value": "1",
                        },
                        "UnitPrice": "1500",
                        "Qty": invoiceForm.activeLasers
                    },
                    "Description": "The price for a service of " + invoiceForm.activeLasers + " lasers is " + invoiceForm.activeLasers * 1500 + "$, the unit price for laser service is 1500$"
                }
            ],
            "CustomerRef": {
                "value": invoiceForm.customer.Id
            }
        }

        //TODO: add business logic to the server
        let now = new Date();
        let nextMonth;
        if (now.getMonth() == 11) {
            nextMonth = new Date(now.getFullYear() + 1, 0, now.getDate());
        } else {
            nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
        }

        let invoiceDTO = {

            companyID: '9c324c2f-17b3-4c29-9c67-2967a8105fb3',
            amount: invoiceForm.activeLasers * 1500,
            item: "Laser Services",
            dueDate: momentParser(nextMonth).format('YYYY-MM-DD'),
            financeID:'0dea1ff2-f7df-4d97-a558-954788a50325',
            unitPrice: 1500,
            status: 'Opened',
            quantity: invoiceForm.activeLasers,
            description: "The price for a service of " + invoiceForm.activeLasers + " lasers is " + invoiceForm.activeLasers * 1500 + "$, the unit price for laser service is 1500$"
        }

        try {

            let response = await fetch('http://localhost:3000/invoices/invoice/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })

            let result = await API.graphql(graphqlOperation(createInvoice, {input: invoiceDTO}))

            setInvoiceForm({...invoiceForm, formStatus: 'invoiceCreated'})
            console.log(response);
        } catch (err) {
            console.log('error creating', err)
        }
    }

    function initForm() {

        setInvoiceForm({...invoiceForm, formStatus: 'createMode'})

    }

    return (
        <>
            {
                auth ?
                    <Container>

                        <Row className={'accountingTop'}>
                            <h3>Accounting Management</h3>
                        </Row>
                        {

                            invoiceForm.formStatus === 'createMode' || invoiceForm.formStatus === 'updateMode' ?
                                <Form className={'accountingForm'}>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="customer">
                                            <Form.Label>Select Company</Form.Label>
                                            <Form.Control as="select" value={invoiceForm.customer.DisplayName}
                                                          onChange={handleInputChange}>
                                                {
                                                    customers.map((customer) =>
                                                        <option
                                                            key={customer.toString()}>{customer.DisplayName}</option>
                                                    )
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="month">
                                        </Form.Group>
                                    </Form.Row>
                                </Form>
                                :
                                <></>
                        }


                        <SubmitInvoice invoiceForm={invoiceForm}

                                       createInvoice={createInvoiceQB}
                                       initForm={initForm}/>

                    </Container> :
                    <Row></Row>
            }
        </>
    );

}
