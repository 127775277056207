import React from "react";

import Amplify from 'aws-amplify';
import awsconfig from '../../aws-exports';
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

import {Roller} from 'react-spinners-css';

Amplify.configure(awsconfig);

const LaserImage = (props) => {


    return (
        <Container>
            {props.laserUrl === '' ?
                <Roller className={'imgRoller'} color="#4d94ff" size={60}/> : <Image src={props.laserUrl}/>}
        </Container>
    )

}

export default LaserImage

/*


function Image(props) {
  const ref = useRef(null);

  // ✅ IntersectionObserver is created lazily once
  function getObserver() {
    if (ref.current === null) {
      ref.current = new IntersectionObserver(onIntersect);
    }
    return ref.current;
  }

  // When you need it, call getObserver()
  // ...
}
 */