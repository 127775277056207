import React, {useContext} from "react";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import 'react-phone-number-input/style.css'
import {AuthContext} from "../../../contexts/authContext";

import PhoneInput from "react-phone-number-input";
import Button from "react-bootstrap/Button";
import {Roller} from "react-spinners-css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {actionCreators} from '../../../state_management/actions/authActions'


export default function SignUpForm() {

    const {dispatch, authState, loginForm, onChange, signUp } = useContext(AuthContext);
    let error = authState.error;

    //TODO: add reCAPTCHA

    return (
        <>
            <Form noValidate validated={error !== ""} >
                <div className={'title'}>Create a new account</div>
                <Form.Group controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        value={loginForm.username}
                        placeholder="username"
                        type="text"
                        maxLength="30"
                        required
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error.username}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        value={loginForm.password}
                        type="password"
                        required
                        placeholder="Password"
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error.password}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        value={loginForm.email}
                        type="email"
                        required
                        placeholder="email"
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error.email}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="company">
                    <Form.Label>Company</Form.Label>
                    <Form.Control
                        value={loginForm.company}
                        placeholder="company"
                        type="text"
                        maxLength="30"
                        required
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error.company}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="phone">
                    <Form.Label>Phone Number</Form.Label>
                    <PhoneInput
                        defaultCountry="US"
                        placeholder="Enter phone number"
                        type="phone"
                        required
                        onChange={onChange}/>
                </Form.Group>
                    <Form.Label className={'fieldInvalid'}>
                        {error.phone}

                    </Form.Label>

                {authState.loading ?
                    <></>
                    :
                    <Button variant="outline-success" onClick={signUp}>
                        Sign Up <Icon.ShieldCheck size={20}/>
                    </Button>
                }
            </Form>
            <Form.Label className={'changeStateMessageLabel'}>Have an account?
                <span className={'changeStateMessage'} onClick={() => {
                    dispatch(actionCreators.signIn(false, ""))
                }}>Sign In</span>
            </Form.Label>
            {authState.loading ?
                <Row>
                    <Col md={{span: 4, offset: 4}}>
                        <Roller color="#70db70" size={30} className={'spinnerSubmit'}/>
                    </Col>
                </Row> :
                <></>
            }
            {error?.message === '' ?
                <></>:
                <Row>
                    <Col  className={'loginError'}>
                        {error.message}
                    </Col>
                </Row>

            }
        </>

    )
}

