import {AuthProvider} from "./authContext";

export default function AppProvider(props){

    return (
        <AuthProvider>

            {props.children}

        </AuthProvider>


    )

}



