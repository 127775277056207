import React, {useContext} from "react";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import {AuthContext} from "../../../contexts/authContext";

import Button from "react-bootstrap/Button";
import {Roller} from "react-spinners-css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {actionCreators} from '../../../state_management/actions/authActions'


export default function ConfirmSignUpForm() {

    const {dispatch, authState, loginForm ,onChange, confirmSignUp} = useContext(AuthContext);
    let error = authState.error;

    return (
        <>
            <Form noValidate validated={error !== ""} >
                <div className={'title'}>Please enter your verification code</div>
                <Form.Group controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        value={loginForm.username}
                        placeholder="username"
                        type="text"
                        maxLength="30"
                        required
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error.username}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="verificationCode">
                    <Form.Label>Confirmation code</Form.Label>
                    <Form.Control
                        value={loginForm.verificationCode}
                        type="text"
                        required
                        placeholder="Verification code"
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error.verificationCode}
                    </Form.Control.Feedback>
                </Form.Group>
                {authState.loading ?
                    <Row>
                        <Col md={{span: 4, offset: 4}}>
                            <Roller color="#70db70" size={30} className={'spinnerSubmit'}/>
                        </Col>
                    </Row>
                    :
                    <Button variant="outline-success" onClick={confirmSignUp}>
                        Confirm <Icon.ShieldCheck size={20}/>
                    </Button>
                }
            </Form>
            <Form.Label className={'changeStateMessageLabel'}>
                <span className={'changeStateMessage'} onClick={() => {
                    dispatch(actionCreators.signIn(false, ""))
                }}>Back to Sign In</span>
            </Form.Label>
            {error?.message === '' ?
                <></>:
                <Row>
                    <Col  className={'loginError'}>
                        {error.message}
                    </Col>
                </Row>

            }
        </>

    )
}


