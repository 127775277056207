import React, {useContext} from "react";
import {Route, Redirect} from "react-router-dom";
import {AuthContext} from "../contexts/authContext";

export default function PrivateRoute({ children, ...rest }) {

    let {authState} = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                authState.status === 'signedIn' ?
                    (
                        children
                    ) :
                    (
                        <Redirect to={{pathname: "/Login",state: { from: location }}}/>
                    )
            }
        />
    );
}
