import React from 'react'
import * as Icon from "react-bootstrap-icons";

export default function StepsHeader(props) {

    return (
        <div className={"bs-stepper-header"}>
            {props.steps.map(step => <HeaderStructure stepName={step}/>)}
            <StepSummary/>
        </div>
    )
}

function HeaderStructure(props) {

    let step = null;

    switch (props.stepName) {

        case "LaserType":
            step = <StepLaserType/>
            break;
        case "Clinic":
            step = <StepMedicalClinic/>
            break;
        case "DateRange":
            step = <StepDateRange/>
            break;
        case "SerialNumber":
            step = <StepSerialNumber/>
            break;
        case "Name":
            step = <StepName/>
            break;
        case "Location":
            step = <StepLocation/>
            break;
        case "Description":
            step = <StepDescription/>
            break;
        case "Image":
            step = <StepImage/>
            break;
        default:
            break;
    }

    return (
        <>
            {step}
            <div className="line"></div>
        </>

    )
}


function StepLaserType() {

    return (
        <div className="step" data-target="#laserType">
            <button className="step-trigger">
                       <span className="bs-stepper-circle">
                             <Icon.LayoutTextWindowReverse className={'myIcon'} size={23}/>
                       </span>
                <span className="bs-stepper-label">Laser Type</span>
            </button>
        </div>
    )
}

function StepMedicalClinic() {

    return (
        <div className="step" data-target="#medicalClinic">
            <button className="step-trigger">
                    <span className="bs-stepper-circle">
                          <Icon.GeoAltFill className={'myIcon'} size={23}/>
                    </span>
                <span className="bs-stepper-label">Clinic</span>
            </button>
        </div>
    )
}

function StepDateRange() {

    return (
        <div className="step" data-target="#rangeDate">
            <button className="step-trigger">
                    <span className="bs-stepper-circle">
                          <Icon.CalendarWeek className={'myIcon'} size={23}/>
                     </span>
                <span className="bs-stepper-label">Date Range</span>
            </button>
        </div>
    )
}

function StepSerialNumber() {

    return (
        <div className="step" data-target="#serialNumber">
            <button className="step-trigger">
                    <span className="bs-stepper-circle">
                          <Icon.BookmarkCheck className={'myIcon'} size={23}/>
                     </span>
                <span className="bs-stepper-label">Serial Number</span>
            </button>
        </div>
    )
}

function StepName() {

    return (
        <div className="step" data-target="#name">
            <button className="step-trigger">
                    <span className="bs-stepper-circle">
                          <Icon.BookmarkCheck className={'myIcon'} size={23}/>
                     </span>
                <span className="bs-stepper-label">Name</span>
            </button>
        </div>
    )
}

function StepDescription() {

    return (
        <div className="step" data-target="#description">
            <button className="step-trigger">
                    <span className="bs-stepper-circle">
                          <Icon.BookmarkCheck className={'myIcon'} size={23}/>
                     </span>
                <span className="bs-stepper-label">Description</span>
            </button>
        </div>
    )
}

function StepImage() {

    return (
        <div className="step" data-target="#img">
            <button className="step-trigger">
                    <span className="bs-stepper-circle">
                          <Icon.BookmarkCheck className={'myIcon'} size={23}/>
                     </span>
                <span className="bs-stepper-label">Image</span>
            </button>
        </div>
    )
}

function StepLocation() {

    return (
        <div className="step" data-target="#location">
            <button className="step-trigger">
                    <span className="bs-stepper-circle">
                          <Icon.GeoAltFill className={'myIcon'} size={23}/>
                     </span>
                <span className="bs-stepper-label">Location</span>
            </button>
        </div>
    )
}


function StepSummary() {

    return (
        <div className="step" data-target="#submit">
            <button className="step-trigger">
                    <span className="bs-stepper-circle">
                          <Icon.ClipboardCheck className={'myIcon'} size={23}/>
                    </span>
                <span className="bs-stepper-label">Submit</span>
            </button>
        </div>
    )
}


