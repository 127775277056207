import React, {useState, useEffect} from 'react'

import Container from "react-bootstrap/Container";


import {API, graphqlOperation, Storage} from 'aws-amplify';
import {createLaser, updateLaser} from '../../../graphql/mutations';
import {listLaserTypes, listMedicalClinics} from '../../../graphql/queries';
import {useParams} from "react-router-dom";

import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper';

import 'react-day-picker/lib/style.css';
import '../../../style/Lasers.css';

import LaserInput from "../inputElements/LaserTypeInput";
import ClinicInput from "../inputElements/ClinicInput";
import SerialNumberInput from '../inputElements/SerialNumberInput'
import Submit from "../Submit"
import StepsHeader from "../StepsHeader";
import momentParser from "moment";


// TODO: custom hooks + con

let initCreateFormState = {
    laserType: 'CO2',
    typeID: '',
    serialNumber: '',
    medicalClinic: {lat: 30.1933, lng: -85.6588},
    date: momentParser(new Date()).format('YYYY-MM-DD'),
    formStatus: 'createMode'

}
const initAppData = {
    arrImgUrl: [],
    clinics: [],
    types: []
}


export default function LaserControlled(props) {

    let {id, laserType, serialNumber, clinic, typeID} = useParams();

    let initFormState;

    if (laserType && serialNumber && clinic && typeID) {
        initFormState = {
            laserType: laserType,
            serialNumber: serialNumber,
            typeID: typeID,
            medicalClinic: {lat: 30.1933, lng: -85.6588},
            date: momentParser(new Date()).format('YYYY-MM-DD'),
            formStatus: 'updateMode'
        }

    } else {
        initFormState = initCreateFormState;
    }


    const [laserForm, setLaserForm] = useState(initFormState)
    const [appData, setAppData] = useState(initAppData)
    const [selectedImgUrl, setSelectedImgUrl] = useState('')
    const [stepper, setStepper] = useState({})

    const structureForm = [

        {
            description:
                laserForm.formStatus === 'createMode' ? "Creation Date" : "Update Date",
            value: laserForm['date']
        },

        {description: "Laser Type", value: laserForm['laserType']},
        {description: "Serial Number", value: laserForm['serialNumber']},
        {description: "Location", value: laserForm['medicalClinic'].clinicName},
    ]


    useEffect(() => {
//ref
        setStepper(new Stepper(document.querySelector('#stepper1'),
            {
                linear: false,
                animation: true
            }))

        async function initData() {
            let clinics = await initClinics();
            let {types, typeID} = await initTypes();
            let arrImgUrl = await initImages(types)

            if (laserForm.formStatus !== 'updateMode') {
                setLaserForm({...laserForm, medicalClinic: clinics[0], typeID: typeID})
            }

            setAppData({...appData, clinics: clinics, arrImgUrl: arrImgUrl, types: types});
        }

        initData();
    }, [])

    async function initTypes() {


        try {

            let data = await API.graphql({query: listLaserTypes})

            let types = data.data.listLaserTypes.items;

            let typeID = types.find(laserType => laserType.laserName === 'CO2').id

            return {types: types, typeID: typeID};

        } catch (err) {

            console.log(err)
        }
    }

    async function initImages(types) {

        let arrImgUrl = [];

        try {

            const images = await Storage.list('f4f21382-9179-4da0-9630-8919a203950d/')

            for (const img of images) {
                let imgUrl = await Storage.get(img.key);
                //let imgUrl = await Storage.get(img.key, {expires: 60});
                const typeLaser = types.find(type => 'f4f21382-9179-4da0-9630-8919a203950d/' + type.id === img.key);

                arrImgUrl.push({
                    kek: img.key,
                    imgUrl: imgUrl,
                    laserName: typeLaser.laserName,
                    description: typeLaser.description
                })
            }
            if (laserForm.formStatus === 'updateMode') {

                setSelectedImgUrl(arrImgUrl.find(laserIndex => laserIndex.laserName === laserForm.laserType).imgUrl);

            } else {
                setSelectedImgUrl(arrImgUrl[0].imgUrl);
            }
            return arrImgUrl

        } catch (err) {

            console.log(err)
        }
    }

    async function initClinics() {

        try {
            let data = await API.graphql({query: listMedicalClinics})

            let clinics = data.data.listMedicalClinics.items;
            if (laserForm.formStatus === 'updateMode') {
                let updateClinic = clinics.find(medicalClinic => medicalClinic.clinicName === clinic)
                setLaserForm({...laserForm, medicalClinic: updateClinic})

            }

            return clinics;

        } catch (err) {
            console.log(err)
        }
    }


    async function handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        const id = target.id;


        if (id === 'laserType') {
            setSelectedImgUrl(appData.arrImgUrl.find(laserIndex => laserIndex.laserName === value).imgUrl)
            let typeID = appData.types.find(laserType => laserType.laserName === value).id
            setLaserForm({...laserForm, [id]: value, typeID: typeID})

        } else if (id === 'medicalClinic') {
            value = appData.clinics.find(clinic => clinic.clinicName === value)
            setLaserForm({...laserForm, [id]: value})

        } else {
            setLaserForm({...laserForm, [id]: value})
        }
    }


    async function createLaserFun() {

        //TODO: add business logic to server

        setLaserForm({...laserForm, formStatus: 'isLoadingCreate'})

        let laserDTO = {
            serialNumber: laserForm.serialNumber,
            companyID: 'f4f21382-9179-4da0-9630-8919a203950d',
            typeID: laserForm.typeID,
            locationID: laserForm.medicalClinic.id,
            activeStatus: false
        }

        try {
            let result = await API.graphql(graphqlOperation(createLaser, {input: laserDTO}))
            setLaserForm({...laserForm, formStatus: 'created'})
            console.log(result)
        } catch (err) {
            console.log(err)
        }
    }

    async function updateLaserFun() {

        //TODO: add business logic to server

        setLaserForm({...laserForm, formStatus: 'isLoadingUpdate'})

        let laserDTO = {
            id: id,
            serialNumber: laserForm.serialNumber,
            companyID: 'f4f21382-9179-4da0-9630-8919a203950d',
            typeID: laserForm.typeID,
            locationID: laserForm.medicalClinic.id,
            activeStatus: true
        }

        try {
            let result = await API.graphql(graphqlOperation(updateLaser, {input: laserDTO}))
            setLaserForm({...laserForm, formStatus: 'updated'})
            console.log(result)
        } catch (err) {
            console.log(err)
        }
    }

    function initForm() {

        setLaserForm({
            laserType: 'CO2',
            serialNumber: '',
            typeID: '',
            date: momentParser(new Date()).format('YYYY-MM-DD'),
            medicalClinic: appData.clinics[0],
            formStatus: 'createMode'
        })
        setSelectedImgUrl(appData.arrImgUrl[0].imgUrl);
        stepper.reset()
    }

//TODO: use context
    return (
        <Container>
            <div id="stepper1" className="bs-stepper">
                <div>
                    <StepsHeader steps={["LaserType", "SerialNumber", "Clinic"]}/>
                    <div className="bs-stepper-content">
                        <LaserInput handleInputChange={handleInputChange}
                                    selectedImgUrl={selectedImgUrl}
                                    selectedLaserType={laserForm.laserType}
                                    types={appData.types}
                                    stepper={stepper}/>
                        <ClinicInput handleInputChange={handleInputChange}
                                     clinics={appData.clinics}
                                     selectedMedicalClinic={laserForm.medicalClinic}
                                     stepper={stepper}
                                     route={false}/>
                        <SerialNumberInput handleInputChange={handleInputChange}
                                           stepper={stepper}
                                           selectedSerialNumber={laserForm.serialNumber}/>
                        <Submit form={laserForm}
                                stepper={stepper}
                                resource={{one: 'Laser', plural: 'Lasers'}}
                                navOption={"/Lasers"}
                                createMode={true}
                                structureFrom={structureForm}
                                create={createLaserFun}
                                update={updateLaserFun}
                                initForm={initForm}/>
                    </div>
                </div>
            </div>
        </Container>
    );

}






